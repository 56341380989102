import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { isMobile, getLastSearchWards, setLastSearchWards } from "../common/Util";
import { useRef, useEffect } from 'react';
import { Icon } from "../common/Icon";
import { useSearchBox } from 'react-instantsearch';

export default function SearchBox(props) {
  const { refine } = useSearchBox(props);
  const inputRef = useRef(null);
  const t = window.t;

  const searchBox = {
    margin: isMobile() ? '0 1em 0' : '0 3em 0',
    paddingTop: '2em'
  }

  const textBoxContainerStyle = {
    display:'flex', 
    margin:'auto'
  }

  useEffect(() => {
    const words = getLastSearchWards();
    if (words && words.trim() !== '') {
      inputRef.current.value = words;
      refine(inputRef.current.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={searchBox}>
      <form
        action=""
        role="search"
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
          event.stopPropagation();

          if (inputRef.current && inputRef.current.value.trim() !== ``) {
            refine(inputRef.current.value);
            setLastSearchWards(inputRef.current.value);
          }
        }}
      >
        <div style={textBoxContainerStyle}>

          <TextField
            inputRef={inputRef}
            spellCheck={false}
            inputProps={{ 
              maxLength: 512,
              autoComplete: 'off',
              autoCorrect: 'off',
              autoCapitalize: 'off',
              spellCheck: false,
              type: 'search'
            }}
            fullWidth
            label={t.search}
          />
          <Button aria-label="delete" color="primary">
            <Icon.Search />
          </Button>
        </div>
      </form>
    </div>
  );
}
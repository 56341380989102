import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db, clearCache, setHeaderInfo, SwitchStyle, isMobile, moveToTop } from "../common/Util";
import { resizeMapContiner, setMap, AimingImage, MapSearch } from "./MapEditUtil";
import { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Icon } from "../common/Icon";
import * as STYLE from "./../common/Style";
import AppBar from '@mui/material/AppBar';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';

export default function NewMap(props) {

  const t = window.t;
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [titleError, setTitleError] = useState();
  const [title, setTitle] = useState('');
  const mapPlaceHolderRef = useRef(null);
  const [snackBarMsg, setSnackBarMsg] = useState(null);
  const [mapLoading, setMapLoading] = useState(true);
  const [mode, setMode] = useState('dark');

  setHeaderInfo(`${t.createNew} - Toboggar`, '', t.lang);

  useEffect(() => {

    setMap(props, mapPlaceHolderRef.current, t.defaultPoint, true);
    setMapLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => resizeMapContiner(
    props.mapContainer.current, 
    mapPlaceHolderRef.current.getBoundingClientRect(),
    props.map.current)
  ,[props.dummyCounter, props.mapContainer, props.map]);
  
  const mapPlaceHolderStyle = {
    position: 'relative',
    pointerEvents: 'none',
    height: '300px', 
    width:'100%', 
    margin: 'auto',
  }

  const containerStyle = {
    width: isMobile() ? '100%' : '90%', 
    maxWidth: 600,
    paddingTop:'2em',
    margin:'0 auto',
    display: 'flex',
    flexDirection: 'column'
  }

  const captionStyle = {
    color: STYLE.COLOR_TERTIARY,
    margin: '10px 0 5px',
    paddingLeft: '10px',
  }

  const snackBarStyle = { 
    vertical: 'top', 
    horizontal:'center' 
  }

  const mapInstractionStyle = {
    textAlign: 'center',
    margin: '20px 0 10px',
    color: STYLE.COLOR_ON_BACKGROUND,
  }

  const iconStyle = {
    height: '18px',
    width: '18px',
    strokeWidth: 2
  }

  const dummyProgressBar = {
    height: '4px'
  }
  
  const logoStyle = {
    width: '110px',
  }

  const SwitchStyleStyle = {
    position: 'absolute', 
    top: '35px',
    left: '5px'
  }

  const mapSearchStyle = {    
    position: 'absolute', 
    top: '5px',
    left: '5px',
    pointerEvents: 'auto',
    width: '300px',
  }

  return (
    <>
      <AppBar position="static" sx={{p:1, flexDirection: 'row'}}>
        <Breadcrumbs sx={{ flexGrow: 1, m: 'auto' }} aria-label="breadcrumb">
          <Link
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            onClick={(e) => {
              moveToTop(navigate);
              e.preventDefault();
            }}
            href="."
          >
            <img style={logoStyle} alt="Toboggar" src="/logo.svg" />
          </Link>
          <Typography
            sx={{ display: 'flex', alignItems: 'center' }}
            color="text.primary"
          >
            {t.createNew}
          </Typography>
        </Breadcrumbs>
        <div>
          <IconButton
            onClick={() => {
              setLoading(true);
              navigate(location.state?.back ? -1 : '/');
            }}
            aria-label={t.back}
            size="middle"
            color="primary"
          >
            <Icon.Back style={iconStyle} title={t.back} />
          </IconButton>
        </div>
      </AppBar>
      {loading ? <LinearProgress /> : <div style={dummyProgressBar}></div>}
      <div style={containerStyle}>
        <h2 style={captionStyle}>{t.createNew}</h2>
        <Card>
          <CardContent>
          <TextField 
            value={title}
            label={t.mapTitle}
            inputProps={{maxLength: 50}}
            variant="filled"
            error={titleError}
            onChange={(e) => {
              setTitleError(!e.target.value || e.target.value.trim() === '');
              setTitle(e.target.value);
            }}
            helperText={titleError ? t.required : `${title?.length ?? 0}/50`}
            required fullWidth/>

          <div style={mapInstractionStyle}>{t.mapInstractionNew}</div>
          <div ref={mapPlaceHolderRef} style={mapPlaceHolderStyle}>
            <AimingImage mode={mode} />
            <div style={mapSearchStyle}>
              <MapSearch map={props.map.current}/>
            </div>
            <SwitchStyle
              loading={mapLoading} 
              map={props.map.current}
              style={SwitchStyleStyle}
              size='small'
              iconSize='16px'
              onLoad={(mode) => setMode(mode)}
            />
          </div>
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button
              disabled={titleError !== false || loading}
              size="small"
              startIcon={<Icon.Ok/>} 
              onClick={async () => {
                setLoading(true);
                try {
                  const startPos = props.map.current.getCenter();
                  const mapRef = await addDoc(collection(db, "maps"), {
                    auther: props.user.userId,
                    draft: true,
                    lang: t.lang,
                    published: serverTimestamp(),
                    title: title.trim(),
                    type: 'FeatureCollection',
                    uid: props.user.uid,
                    like: 0,
                    update: serverTimestamp(),
                    features: [{
                      geometry: {
                        coordinates: [startPos.lng, startPos.lat],
                        type: 'Point'
                      },
                      id: 1,
                      properties: {},
                      type: 'Feature'
                    }]
                  });
                  clearCache();
                  navigate('/edit/' + mapRef.id, { state: {back: location.state?.back}, replace: true });
                } catch(error) {
                  setLoading(false);
                  console.error("Error create map: ", error);                
                  setSnackBarMsg(error.name + ' ' + error.message);
                }
              }}
            >{t.ok}
            </Button>
            <Button
              onClick={() => {
                setLoading(true);
                navigate(location.state?.back ? -1 : '/');
              }}
              size="small"
              startIcon={<Icon.Cancel/>} 
            >{t.cancel}
            </Button>
          </CardActions>
        </Card>
      </div>
      <Snackbar 
        autoHideDuration={6000} 
        anchorOrigin={snackBarStyle} 
        open={snackBarMsg !== null} 
        message={snackBarMsg}
        onClose={() => setSnackBarMsg(null)}
      />
    </>
  );
};

NewMap.propTypes = {
  user: PropTypes.object,
  map: PropTypes.object,
  mapContainer: PropTypes.object,
  dummyCounter: PropTypes.number
}

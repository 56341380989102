import { useEffect, useState, Fragment, useCallback } from 'react';
import { useNavigate, useNavigation, useOutletContext, useLoaderData, ScrollRestoration } from "react-router-dom";
import { collection, collectionGroup, query, where, getDocs, orderBy, limit, startAfter, documentId } from "firebase/firestore"; 
import { Icon } from "../common/Icon";
import { db, isMobile, numToK, isTouchDevice, getFromCache, setIntoCache } from "./../common/Util";
import Container from '@mui/material/Container';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as STYLE from "./../common/Style";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Card from '@mui/material/Card';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';

function Element(props) {

  const t = window.t;
  const loadedData = useLoaderData();
  const navigate = useNavigate();
  const navigation = useNavigation();

  const [, setMenu] = useOutletContext().menuState;
  const [loading, setLoading] = useOutletContext().loadingState;
  const [userMaps, setUserMaps] = useState(loadedData.maps);
  const [lastSnapshot, setLastSnapshot] = useState(loadedData.lastSnapshot);
  const [snackBarMsg, setSnackBarMsg] = useState(null);
  const user = useOutletContext().user;

  const isLoading = useCallback(() => {
    return navigation.state !== 'idle';
  }, [navigation.state]);

  useEffect(() => {
    setMenu(1);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isLoading())
      setLoading(false);
    // eslint-disable-next-line
  },[navigation.state, loading, isLoading]);

  async function reload() {
    setLoading(true);
    try {
      const ret = await load(user);
      setUserMaps(ret.maps);
      setLastSnapshot(ret.lastSnapShot);
      setTimeout(() => setLoading(false), 1000);
    } catch(error) {
      console.error("Error sing out: ", error);                
      setSnackBarMsg(error.name + ' ' + error.message);
    }
  }

  const listStyle = {
    wordBreak: 'break-all' 
  }

  const likeIconStyle = {
    width: '18px',
    height: '18px',
  }

  const likeStyle = {
    marginRight: '1em',
    fontSize: '110%',
    display: isMobile() ? 'block' : 'inline'
  }

  const publishedStyle = {
    color: STYLE.COLOR_ON_SRFACE_VARIANT,
    fontSize: '90%',
    marginBottom: '8px'
  }

  const descStyle = {
    display: '-webkit-box',
    marginBottom: 0,
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden'
  }

  const mapImageStyle = {
    justifyContent: 'center'
  }

  const imageStyle = {
    maxWidth: "100px",
    maxHeight: "100px",
  };
  
  const pullContentStyle = {
    color: STYLE.COLOR_ON_SECONDARY_CONTAINER, 
    textAlign: 'center',
    backgroundColor: STYLE.COLOR_SECONDARY_CONTAINER,
    position: 'absolute',
    borderRadius: '0 0 10px 10px',
    padding: '5px 10px',
    left: '50%',
    zIndex: '100',
    animationName: 'pullDown',
    animationDuration: '0.5s',
    animationFillMode: 'forwards',
  }

  const reloadStyle = {
    height: '18px',
    width: '18px',
    strokeWidth: 2
  }

  const snackBarStyle = { 
    vertical: 'top', 
    horizontal:'center' 
  }

  return (
    <>
      <style>{`
        @keyframes pullDown {
          from {
            transform: translate(-50%, -40px);
            opacity: 0;
          }
        
          to {
            transform: translate(-50%, 0);
            opacity: 1;
          }
        }
       `}</style>
      <ScrollRestoration/>
      <Card>
        <IconButton
          size="middle"
          color="primary"
          onClick={reload}
          sx={{ml:1, mt:0.2, mb:0.2, display: isMobile() ? 'none' : 'inline-flex'}}
        >
          <Icon.Reload 
            title={t.reload} 
            style={reloadStyle}/>
        </IconButton>
      </Card>
      <Container maxWidth="xl" style={listStyle}>
        <ScrollRestoration/>
        <InfiniteScroll 
          dataLength={userMaps.length}
          next={async () => {
            setLoading(true);
            try {
              const ret = await retrieve(user, 7, lastSnapshot);
              let newList;
              setUserMaps(prevItems => {
                newList = [...prevItems, ...ret.maps]
                return newList;
              });
              setLastSnapshot(ret.lastSnapshot);
              setIntoCache('l:' + user.uid, {maps: newList, lastSnapshot: ret.lastSnapshot});
            } catch(error) {
              console.error("Error sing out: ", error);                
              setSnackBarMsg(error.name + ' ' + error.message);
            }
          }} 
          hasMore={lastSnapshot && userMaps.length < 1000}
          pullDownToRefresh={isTouchDevice()}
          refreshFunction={reload}
          pullDownToRefreshThreshold={50}
          releaseToRefreshContent={
            <div style={pullContentStyle}><Icon.Reload/>{t.reload}</div>
          }
          >
            <List sx={{ width: '100%'}}>
            {userMaps.map((map) => {
              return <Fragment key={map.uid}>
                <ListItem disablePadding divider>
                  <ListItemButton onClick={() => {
                    setLoading(true);
                    navigate('/m/' + map.uid, { state: {back: true} });
                  }}>
                    <ListItemIcon sx={{width:100, mr:2}} 
                    style={mapImageStyle}>
                      {map.image ? 
                        <img alt={map.title} style={imageStyle} src={map.image}/>
                        : <Icon.Image />}
                    </ListItemIcon>
                    <ListItemText
                      primary={map.title ?? t.noTitle}
                      secondary={<>
                        <span style={likeStyle} ><Icon.Heart viewBox="0 -2 24 24" title={t.like} style={likeIconStyle}/>{numToK(map.like)}</span>
                        <span style={publishedStyle}>{map.draft ? t.draft : t.dtFormater(map.published.toDate())}</span>
                        <span style={descStyle}>{map.description}</span>
                      </>}
                      primaryTypographyProps={{sx:{ color: 'primary.main' }}}
                    />
                  </ListItemButton>
                </ListItem>
              </Fragment>
            })}
            </List>
        </InfiniteScroll>
      </Container>
      <Snackbar 
        autoHideDuration={6000} 
        anchorOrigin={snackBarStyle} 
        open={snackBarMsg !== null} 
        message={snackBarMsg}
        onClose={() => setSnackBarMsg(null)}
      />
    </>

  );
}

async function load(user) {

  const cache = getFromCache('l:' + user.uid);
  if (cache) 
    return cache;

  const ret = retrieve(user, 5);
  setIntoCache('l:' + user.uid, ret);
  return ret;

}

async function retrieve(user, pageLength, lastSnapshot) {

  const res = {maps: null, lastSnapshot: null};

  let likeRefQuery = query(collectionGroup(db, `likes`), where('userRef', '==', user.uid), orderBy("liked", "desc"), limit(pageLength));
  if (lastSnapshot)
    likeRefQuery = query(likeRefQuery, startAfter(lastSnapshot));
  const likeRefDoc = await getDocs(likeRefQuery);
  const mapRefs = new Map();
  let lastRef;
  likeRefDoc.forEach((doc) => {
    mapRefs.set(doc.data().mapRef, doc.data().liked);
    lastRef = doc;
  });
  if (mapRefs.size === pageLength)
    res.lastSnapshot = lastRef;

  const maps = [];

  if (mapRefs.size > 0) {
    const mapQuery = query(collection(db, "maps"), 
      where(documentId(), 'in', [...mapRefs.keys()]),
      where('draft', '==', false),
      orderBy("__name__", "asc")
    );
    const mapDoc = await getDocs(mapQuery);
    mapDoc.forEach((doc) => {
      const data = doc.data();
      data.uid = doc.id;
      data.liked = mapRefs.get(doc.id);
      maps.push(data);
    });
  }
  res.maps = maps.toSorted((a, b) => b.liked.toDate().getTime() - a.liked.toDate().getTime());

  return res;
}

function LoaderHandle(user) {
  return ({request, params}) => {
    const res = load(user);
    setIntoCache('l:' + user.uid, res);
    return res;
  }
}

const Like = {
  Element,
  LoaderHandle,
};

export default Like;
import * as STYLE from "./../common/Style";
import React, { useRef, useEffect } from 'react';
import {isMobile} from "./../common/Util";
import {TransportIcon} from "./../common/Icon";
import PropTypes from 'prop-types';

const Flap = React.memo((props) => {
  const flap = useRef();
  const flapT = useRef();
  const flapB = useRef();
  const seq = useRef(0);

  useEffect(() => {
    turn(props.afterEffect);
  });

  async function turn(callback) {
    seq.current = seq.current + 1;
    const currenSeq = seq.current;
    if (props.interval) {
      const icon = TransportIcon[props.interval];
      const image = `<div class="interval" style="top:50%; left:50%; transform:translate(${icon.pos[0][0]}%,${icon.pos[0][1]}%)">${icon.element}</div>`;
  
      if (currenSeq !== seq.current) return;
      await turnFlap(image);
      if (currenSeq !== seq.current) return;
      await turnFlap();
  
      for (let j = 1; j < 5; j++) {
        if (currenSeq !== seq.current) return;
        await turnFlap((div) => div.style.transform = `translate(${icon.pos[j][0]}%,${icon.pos[j][1]}%)`);
        if (currenSeq !== seq.current) return;
        await turnFlap();
      }
    }
    if (currenSeq !== seq.current) return;
    let repeat = props.repeat ? 1000 : 1;
    for (let i = 0; i < repeat; i++)
      await turnFlap(props.element, callback);
  }

  async function turnFlap(nextNodes, callback) {
    if (!flap.current) return;
    let isFunc = typeof nextNodes === 'function';

    if (nextNodes) {
      if (isFunc)
        nextNodes(flapT.current.firstChild);
      else
        flapT.current.innerHTML = nextNodes;
    }
    flap.current.style.zIndex = 11;
    flapB.current.style.zIndex = 12;
    flapT.current.style.zIndex = 10;
    flapT.current.style.display = 'block';
    flapB.current.style.display = 'block';
    let animation = flap.current.animate([
      { transform: 'rotateX(-90deg)' }
    ],
      {
        duration: 60
    });
    await animation.finished;
    if (!flap.current) return;
    animation.cancel();
    
    flap.current.style.transform = 'rotateX(90deg)';
    if (nextNodes) {
      if (isFunc)
        nextNodes(flap.current.firstChild);
      else
        flap.current.innerHTML = nextNodes;
    }
    flap.current.style.zIndex = 11;
    flapB.current.style.zIndex = 10;
    flapT.current.style.zIndex = 12;

    animation = flap.current.animate([
      { transform: 'rotateX(0deg)' }
    ],
      {
        duration: 60,
        fill: 'forwards'
      });
    await animation.finished;
    if (!flap.current) return;
    animation.commitStyles();
    animation.cancel();
    if (nextNodes) {
      if (isFunc)
        nextNodes(flapB.current.firstChild);
      else
        flapB.current.innerHTML = flap.current.innerHTML;
    }
    flapB.current.style.display = 'none';
    flapT.current.style.display = 'none';
    if (callback)
      callback(flap.current.firstChild, flapB.current.firstChild);
  }

  const gapStyle = {
      height: "1px",
      width: isMobile() ? "95vw" : "100%",
      position: "absolute",
      left: isMobile() ? "2vw" : "initial",
      top: isMobile() ? "55px" : "50px",
      backgroundColor: "#000", 
      zIndex: 13,
      opacity: 0.6
  };

  const flapStyle = {
      height: "100px",
      width: isMobile() ? "95vw" : "100%",
      position: "absolute",
      overflow: "hidden",
      background: "linear-gradient(#222, #111, #222, #111)",
      color: STYLE.COLOR_ON_BACKGROUND,
      border: "solid 1px #000",
      boxShadow: "0 2px 5px rgba(0,0,0,0.26)",
      whiteSpace: "nowrap",
      left: isMobile() ? "2vw" : "initial",
      top: isMobile() ? "5px" : "initial",
  };

  const flapTopStyle = {
      ...flapStyle,
      clipPath : "polygon(0 0, 100% 0, 100% 50%, 0 50%)",
      display: "none"
  };

  const flapBottomStyle = {
      ...flapStyle,
      clipPath : "polygon(0 50%, 100% 50%, 100% 100%, 0 100%)",
      display: "none"
  };
  
  return (
    <>
      <div className="flap" id="flap" style={flapStyle} ref={flap}></div>
      <div className="flap" style={flapTopStyle} ref={flapT} ></div>
      <div className="flap" style={flapBottomStyle} ref={flapB}></div>
      <div className="gap" style={gapStyle}></div>
    </>
  );
    
});

Flap.propTypes = {
  repeat: PropTypes.bool,
  interval: PropTypes.string,
  afterEffect: PropTypes.func,
  element: PropTypes.string
}

export default Flap;
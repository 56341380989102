import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from "react-router-dom";
import { getAuth, signInWithPopup, GoogleAuthProvider, TwitterAuthProvider } from "firebase/auth";
import { Icon } from "../common/Icon";
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import * as STYLE from "../common/Style";
import Link from '@mui/material/Link';
import {setHeaderInfo} from "../common/Util";

export default function SignIn(props) {

  const t = window.t;
  const [, setMenu] = useOutletContext().menuState;
  const [, setLoading] = useOutletContext().loadingState;
  const navigate = useNavigate();
  const [snackBarMsg, setSnackBarMsg] = useState(null);
  setHeaderInfo(`${t.login} - Toboggar`, '', t.lang);

  useEffect(() => {
    setMenu(1);
    // eslint-disable-next-line
  }, []);

  function login(provider) {
    const auth = getAuth();
    setLoading(true);
    signInWithPopup(auth, provider)
      .then((result) => {
        setLoading(false);
        navigate('/');
      }).catch((error) => {
        setLoading(false);
        if (error.code === 'auth/popup-closed-by-user')
          return;
        if (error.code === 'auth/user-cancelled')
          return;
        console.error("Error login: ", error);                
        setSnackBarMsg(`Error:${error.message}(${error.code})`);
      });
  }

  const containerStyle = {
    width: '90%', 
    paddingTop:'2em', 
    margin:'0 auto'
  }

  const captionStyle = {
    color: STYLE.COLOR_TERTIARY,
    margin: '10px 0 5px',
    paddingLeft: '10px',
  }

  const authButtonStyle = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '10px',
    marginTop: '1em'
  }

  return (
    <>
    <div style={containerStyle}>
      <h2 style={captionStyle}>{t.login}</h2>
      <Card>
        <CardContent>
          <span>{t.termConfirmation}</span>
          <ul>
            <li>
              <Link href="." onClick={(e) => {
                navigate('/rule/tu');
                setLoading(true);
                e.preventDefault(); 
                }}>{t.termsOfUse}</Link>
            </li>
            <li>
              <Link href="." onClick={(e) => {
                navigate('/rule/pp'); 
                setLoading(true);
                e.preventDefault(); }}>{t.privacyPolicy}</Link>
            </li>
            <li>
              <Link href="." onClick={(e) => {
                navigate('/rule/cp'); 
                setLoading(true);
                e.preventDefault(); }}>{t.contentsPolicy}</Link>
            </li>
          </ul>
          <div style={authButtonStyle}>
            <Button
              startIcon={<Icon.Login />} 
              endIcon={<img width="18px" height="18px" alt="Google" src="/logos/google.png"/>}
              onClick={() => {
                login(new GoogleAuthProvider());
              }}
            >
              {t.loginGoogle}
            </Button>
            <Button 
              startIcon={<Icon.Login />}
              endIcon={<img width="18px" alt="x" src="/logos/x.svg"/>}
              onClick={() => {
                login(new TwitterAuthProvider());
              }}
            >
              {t.loginX}
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
    <Snackbar 
      autoHideDuration={6000} 
      anchorOrigin={{ vertical: 'top', horizontal:'center' }} 
      open={snackBarMsg !== null} 
      message={snackBarMsg}
      onClose={() => setSnackBarMsg(null)}
    />

    </>
  );
}


import { useEffect, useRef } from 'react';
import { Icon } from "./Icon";
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { isMobile} from "../common/Util";
import IconButton from '@mui/material/IconButton';

export default function ImageLoader(props) {

  const t = window.t;
  const imageFileRef = useRef(null);
  const imageLoadHandle = props.onLoad;

  useEffect(() => {
    imageFileRef.current.addEventListener('change', (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (event) => imageLoadHandle(event.currentTarget.result)
        reader.readAsDataURL(file);
      }
    }, false);
  }, [imageLoadHandle]);


  const fileButtonStyle = {
    display: "none"
  }

  return (
    <>
      <input type="file" ref={imageFileRef} accept="image/png, image/jpeg" style={fileButtonStyle}/>
      {props.buttonType === 'icon' ? 
        <IconButton
          color="primary"
          size="small"
          disabled={props.disabled}
          onClick={()=>{
            imageFileRef.current.click();
          }}>
          <Icon.Upload title={t.selectImage} style={props.buttonStyle}/>
        </IconButton>
      : 
        <Button
          size="small"
          startIcon={<Icon.Upload/>} 
          disabled={props.disabled}
          onClick={()=>{
            imageFileRef.current.click();
          }}>{isMobile() ? null : t.selectImage}
        </Button>
      }
    </>
  );
}

ImageLoader.propTypes = {
  onLoad: PropTypes.func,
  buttonType: PropTypes.string,
  buttonStyle: PropTypes.object,
  disabled: PropTypes.bool,
};

import { useEffect } from 'react';
import { useOutletContext, useNavigate } from "react-router-dom";
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { isMobile } from "../common/Util";
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import { Icon } from "../common/Icon";
import * as STYLE from "./../common/Style";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

export default function HowToUse(props) {

  const t = window.t;
  const [, setMenu] = useOutletContext().menuState;
  const navigate = useNavigate();

  useEffect(() => {
    setMenu(2);
    // eslint-disable-next-line
  }, []);

  const containerStyle = {
    display:'grid',
    gridTemplateRows: isMobile() ? 'auto auto auto auto' : 'auto 1fr auto',
    gridTemplateColumns: isMobile() ? '1fr' : '1fr 1fr',
    gap: isMobile() ? '32px': '16px',
    padding: '16px',
  }

  const headerFooterStyle = {
    margin: '1em',
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
    zIndex: 1
  }

  const headerStyle = {
    ...headerFooterStyle,
    gridColumn: '1/2',
  }

  const footerStyle = {
    ...headerFooterStyle,
    gridColumn: isMobile() ? '1/2' : '2/3',
  }

  const addStyle = {
    opacity: '0.8',
    fontSize: '150%',
    margin: '0 0.5em',
    pointerEvents: 'none'
  }

  const publishStyle = {
    opacity: '0.8',
    margin: '0 1em',
    pointerEvents: 'none'
  }

  const publishIconStyle = {
    height: '18px',
    width: '18px',
    strokeWidth: 2,
    marginRight: '8px', 
  }

  const startStyle = {
    width: '130px',
  }

  const pubShareStyle = {
    width: '130px',
  }

  const iconStyle = {
    color: STYLE.COLOR_PRIMARY,
    margin: '15px 0.5em -7px 1em'
  }

  const lableStyle = {
    color: STYLE.COLOR_ON_SRFACE_VARIANT,
    marginRight: '10px',
    fontSize: '14px',
    display: 'block'
  }

  const descStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
    margin: '1.5em 0'
  }

  const descImageStyle = {
    display: 'block',
    margin: 'auto'
  }

  const aimingStyle = {
    ...descImageStyle,
    width: '100px',
  }

  const pubShere = (
    <div style={footerStyle}>
      <img width="130px" height="121.42px" alt="publish & share" src="/howto/publish.svg" style={pubShareStyle} />
      <div>
        {t.howtoPublish.prefix}
        <Fab 
          size="small" 
          color="secondary" 
          variant="extended"
          style={publishStyle} 
        >
          <Icon.Publish style={publishIconStyle} />
          {t.publish}
        </Fab>
        {t.howtoPublish.suffix}
        <br/>
        {t.howtoShare.prefix}
        <Icon.Share style={iconStyle} title={t.share} />
        {t.howtoShare.suffix}
      </div>
    </div>
  );

  return (
    <>
      {useOutletContext().user ? null : 
        <Paper variant="outlined" sx={{width:'95%', m: '1em auto', p: '1em'}} elevation="4">
          {t.guindingToSignin}
          <Button variant="outlined" 
            startIcon={<Icon.Login />} 
            onClick={() => navigate('/signin')}
            id="login-button"
            sx={{ml: '10px'}}
          >
            {t.login}
          </Button>
        </Paper>
      }
      <div style={containerStyle}>
        <div style={headerStyle}>
          <img width="130px" height="82.56px" alt="start" src="/howto/start.svg" style={startStyle} />
          <div>
            {t.howtoStart.prefix}
            <Fab size="small" color="secondary" style={addStyle} >
              <Icon.Plus/>
            </Fab>
            {t.howtoStart.suffix}
          </div>
        </div>
        {isMobile() ? null : pubShere}
        <Card elevation={8}>
          <CardMedia
            sx={{ height: 80, backgroundSize: 'contain', backgroundPosition: 'left', backgroundColor: STYLE.COLOR_SECONDARY_CONTAINER }}
            image="/howto/header1.svg"
            title={t.howtoFavorite}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" color="secondary">
              {t.howtoFavorite}
            </Typography>

            <div style={descStyle}>
              <img width="100px" height="100px" src="/howto/aiming.png" style={aimingStyle} alt="aiming"/>
              <div>
                <Typography variant="body1" color="text.secondary">
                  {t.howtoMapping}
                </Typography>
                <img alt="penguin mapping" src="/howto/pen_map.png" height="200px" width="200px"/>
              </div>
              <Divider sx={{mt:2, mb:2, gridColumn: '1 / 3'}}/>
              <div style={descImageStyle}>
                <span style={lableStyle}>{t.reverse}</span>
                <Switch 
                  color="primary" 
                  checked={true}
                  sx={{pointerEvents: 'none'}}
                />
              </div>
              <div>
                <Typography variant="body1" color="text.secondary">
                  {t.howtoCountdown}
                </Typography>
                <img alt="penguin countdown" src="/howto/pen_count.png" height="200px" width="200px"/>
              </div>
            </div>
          </CardContent>
        </Card>    
        <Card elevation={8}>
          <CardMedia
            sx={{ height: 80, backgroundSize: 'contain', backgroundPosition: 'left', backgroundColor: STYLE.COLOR_SECONDARY_CONTAINER }}
            image="/howto/header2.svg"
            title={t.howtoPlanTrip}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" color="secondary">
            {t.howtoPlanTrip}
            </Typography>
            <div style={descStyle}>
              <div style={descImageStyle}>
                <TextField
                  label={t.day}
                  variant="filled"
                  defaultValue={t.dayFormatter(1)}
                  sx={{width: '80px', mr:'10px', pointerEvents: 'none'}}
                />
                <TextField
                  label={t.time}
                  defaultValue='09:00'
                  variant="filled"
                  sx={{width: '80px', pointerEvents: 'none'}}
                />
              </div>
              <div>
                <Typography variant="body1" color="text.secondary">
                  {t.howtoModelCourse}
                </Typography>
                <img alt="penguin countdown" src="/howto/pen_travel.png" 
                  height={isMobile() ? '100px' : '200px'} width={isMobile() ? '100px' : '200px'}/>
              </div>
              <Divider sx={{mt:2, mb:2, gridColumn: '1 / 3'}}/>
              <div style={descImageStyle}>
                <TextField
                  label={t.baseDate}
                  defaultValue={t.dateFormat}
                  variant="filled"
                  sx={{width: '180px', pointerEvents: 'none'}}
                  InputProps={{
                    endAdornment: <InputAdornment position="start"><Icon.Calendar/></InputAdornment>,
                  }}
                />
              </div>
              <div>
                <Typography variant="body1" color="text.secondary">
                  {t.howtoTravelog}
                </Typography>
                <img alt="penguin shopping" src="/howto/pen_shopping.png" height={isMobile() ? '100px' : '200px'} width={isMobile() ? '100px' : '200px'}/>
              </div>
            </div>
          </CardContent>
        </Card>
        {isMobile() ? pubShere : null}
      </div>
    </>
  );
}

HowToUse.propTypes = {
};

import PropTypes from 'prop-types';
import postscribe from 'postscribe';
import { isMobile } from "./../common/Util";
import { useEffect, } from 'react';

export default function AddMap(props) {

  useEffect(() => {
    if (!props.isEmbedded)
      postscribe(
        '#addPlace',
        isMobile() ? 
        `<script type="text/javascript">rakuten_design="slide";rakuten_affiliateId="0c37c7a8.b2a17ac2.0c37c7a9.69dbb093";rakuten_items="ctsmatch";rakuten_genreId="0";rakuten_size="320x48";rakuten_target="_blank";rakuten_theme="gray";rakuten_border="off";rakuten_auto_mode="on";rakuten_genre_title="off";rakuten_recommend="on";rakuten_ts="1713709785667";</script><script type="text/javascript" src="https://xml.affiliate.rakuten.co.jp/widget/js/rakuten_widget.js?20230106"></script>`
        :
        `<script type="text/javascript">rakuten_design="slide";rakuten_affiliateId="1166403d.125c6a27.1166403e.50b46879";rakuten_items="tra-ctsmatch";rakuten_genreId="0";rakuten_size="468x160";rakuten_target="_blank";rakuten_theme="gray";rakuten_border="off";rakuten_auto_mode="on";rakuten_genre_title="off";rakuten_recommend="on";rakuten_ts="1713607102728";</script><script type="text/javascript" src="https://xml.affiliate.rakuten.co.jp/widget/js/rakuten_widget_travel.js?20230106"></script>`
      );
  }, [props.dummyCounter, props.isEmbedded]);


  return(
    <>
    {
      props.isEmbedded ? null : 
      <div id="addPlace" style={{
        overflow: 'hidden', 
        position: 'absolute', 
        top: '170px', 
        left: isMobile() ? '0' : '6px', 
        width: isMobile() ? '100%' : '468px', 
        height:  isMobile() ? '48px' : '160px',
        textAlign: 'center',
      }}
      >
      </div>
    }
    </>   
  );
}

AddMap.propTypes = {
  dummyCounter: PropTypes.number,
  isEmbedded: PropTypes.bool
}

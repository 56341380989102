import * as STYLE from "./../common/Style";
import { Icon, TransportIcon, EventIcon } from "./../common/Icon";
import { useLoaderData, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useRef, useEffect, useState, useCallback } from 'react';
import mapboxgl from 'mapbox-gl';
import {useAsyncReference, sanitaize, numToK, isMobile, setHeaderInfo, db, MAP_ID, SwitchStyle, changeMapStyle, newMap, mapReset, moveToTop, getLineItems } from "./../common/Util";
import Flap from "./Flap";
import { getDoc, doc, setDoc, serverTimestamp, deleteDoc, Timestamp } from "firebase/firestore"; 
import { getStorage, ref, getBlob } from "firebase/storage";
import PropTypes from 'prop-types';
import Share from './Share';
import AddMap from './AddMap';

function Element(props) {

  const navigate = useNavigate();
  const userMap = useRef(useLoaderData().userMap);
  const like = useRef(useLoaderData().like);
  const numOfLike = useRef(useLoaderData().userMap.like);
  const reverse = useRef();
  const initialVal = useRef(); 
  const [index, setIndex] = useState('t');
  const [flyState, setFlyState] = useAsyncReference('stop');
  const [shareDlg, setShareDlg] = useState(false);
  const allBounds = useRef();
  const flyMapId = useRef();
  const toId = useRef();
  const hoveredPointId = useRef();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const imageCache = useRef({});
  const popupImageFile = useRef();
  const mapStyle = STYLE.MAPBOX_STYLE[searchParams.get('style')] ? searchParams.get('style') : 'simple';
  const [mapLoading, setMapLoading] = useState(true);
  const [descPage, setDescPage] = useState(0);
  const isEmbedded = window !== window.parent;

  const stop = useCallback(() => {
    clearTimeout(toId.current);
    setFlyState('stop');
    setIndex('t');
    props.map.current.fitBounds(allBounds.current, {padding: getBoundPadding()});
    flyMapId.current = null;
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.map, setFlyState]);

  // Memoization to keep flaps when state was not changed
  const titleEventHandle = useCallback((div, divB) => {
    div.querySelector(".description").onclick = () => {
      setIndex('d');
      setDescPage(0);
    };
    div.querySelector(".auther .id").onclick = () => {
      if (isEmbedded)
        window.open('/u/' + userMap.current.auther, 'toboggar');
      else {
        stop();
        setIndex('l');
        navigate('/u/' + userMap.current.auther, { state: {back: true} });  
      }
    };
    div.querySelector(".open").onclick = () => window.open(window.location.pathname, 'toboggar');
    div.querySelector(".share").onclick = () => setShareDlg(true);
    div.querySelector(".edit").onclick = () => {
      stop();
      setIndex('l');
      navigate('/edit/' + userMap.current.id, { state: {back: true} });
    };
    if (props.user && !userMap.current.draft)
      div.querySelector(".like").onclick = (e) => {
        const likeRef = doc(db, `maps/${userMap.current.id}/likes`, props.user.uid);
        const likeB = divB.querySelector(".like");
        if(like.current) {
          deleteDoc(likeRef).catch((e) => console.error('unliked;', e));
          e.currentTarget.classList.remove('liked');
          likeB.classList.remove('liked');
          numOfLike.current = numOfLike.current - 1;
        } else {
          setDoc(likeRef, { liked: serverTimestamp(), mapRef: userMap.current.id, userRef: props.user.uid}).catch((e) => console.error('unliked;', e));
          e.currentTarget.classList.add('liked');
          likeB.classList.add('liked');
          numOfLike.current = numOfLike.current + 1;
        }
        like.current = !like.current;
        const numK = numToK(numOfLike.current);
        div.querySelector(".likeNum").innerHTML = numK;
        divB.querySelector(".likeNum").innerHTML = numK;
      };
  }, [navigate, props.user, stop, isEmbedded]);

  const descEventHandle = useCallback((div) => {
    const element = isMobile() ? div : div.querySelector("svg");
    element.onclick = () => {
      const height = div.querySelector("p").scrollHeight;
      if (height > 100 * (descPage + 1))
        setDescPage(descPage + 1);
      else
        setIndex('t');
    };
  }, [descPage]);

  const setCenter = useCallback((coordinates, zoom) => {
    props.map.current.flyTo({
      center: coordinates,
      offset: [matchMedia('(min-width: 900px)').matches ? 200 : 0, isMobile() ? 50 : 0],
      zoom: zoom ?? props.map.current.getZoom()
    });
  }, [props.map]);

  const itemEventHandle = useCallback((div) => {
    const backNav = div.querySelector(".backNav");
    if (backNav)
      backNav.onclick = () => {
        setIndex(index - 1);
        setCenter(userMap.current.features[index - 1].geometry.coordinates);
      };
    const forwardNav = div.querySelector(".forwardNav");
    if (forwardNav)
      forwardNav.onclick = () => {
        setIndex(index + 1);
        setCenter(userMap.current.features[index + 1].geometry.coordinates);
      };
  }, [index, setCenter]);

  const t = window.t;
  let element;
  let interval;
  let repeat;
  let afterEffect;

  allBounds.current = new mapboxgl.LngLatBounds();
  userMap.current.features.forEach(item => allBounds.current.extend(item.geometry.coordinates));
  reverse.current = userMap.current.order === "reverse";
  initialVal.current = reverse.current ? userMap.current.features.length - 1 : 0

  setUserMapHeader(userMap.current, t);

  if (props.map.current?.getSource(MAP_ID.data)) {
    userMap.current.features.forEach(item => {
      props.map.current.setFeatureState(
        { source: MAP_ID.data, id: item.id },
        { notSelected: Number.isInteger(index) && item.id !== (index + 1) }
      );
    })
  }
  if (props.map.current?.getSource(MAP_ID.lineData)) {
    const lineItems = getLineItems(userMap.current);
    lineItems.forEach((line) => {
      props.map.current.setFeatureState(
        { source: MAP_ID.lineData, id: line.id },
        { notYet: Number.isInteger(index) && line.id > (index + 1) }
      );
    });
  }

  if (index === 't') {
    // Title
    removePopup(props.map.current);

    let dt = userMap.current.draft ? null : userMap.current.published.toDate();
    let title = sanitaize(userMap.current.title, t.noTitle);
    element = `<div class="overview">
      <div class="title" title="${title}">${title}</div>
      <div class="auther"><span class="id">${userMap.current.auther}</span></div>
      <div class="dt">${dt ? t.dtFormater(dt) : t.draft}</div>
      <div class="operation">
        <svg xmlns="http://www.w3.org/2000/svg" class="open icon ${isEmbedded ? '' : 'hide'}" viewBox="0 0 24 24" stroke-width="1.5" width="24" height="24"><title>${t.openNewWindow}</title><defs><style>.cls-6374f8d9b67f094e4896c661-1{fill:none;stroke:currentColor;stroke-miterlimit:10;}</style></defs><polyline class="cls-6374f8d9b67f094e4896c661-1" points="20.59 13.91 20.59 22.5 1.5 22.5 1.5 3.41 10.09 3.41"></polyline><polyline class="cls-6374f8d9b67f094e4896c661-1" points="13.91 1.5 22.5 1.5 22.5 10.09"></polyline><line class="cls-6374f8d9b67f094e4896c661-1" x1="22.5" y1="1.5" x2="10.09" y2="13.91"></line></svg>
        <svg xmlns="http://www.w3.org/2000/svg" class="edit icon ${!isEmbedded && props.user && (props.user.uid === userMap.current.uid)? '' : 'hide'}" viewBox="0 0 24 24" stroke-width="1.5" width="24" height="24"><title>${t.edit}</title><defs><style>.cls-6374f8d9b67f094e4896c676-1{fill:none;stroke:currentColor;stroke-miterlimit:10;}</style></defs><path class="cls-6374f8d9b67f094e4896c676-1" d="M7.23,20.59l-4.78,1,1-4.78L17.89,2.29A2.69,2.69,0,0,1,19.8,1.5h0a2.7,2.7,0,0,1,2.7,2.7h0a2.69,2.69,0,0,1-.79,1.91Z"></path><line class="cls-6374f8d9b67f094e4896c676-1" x1="0.55" y1="22.5" x2="23.45" y2="22.5"></line><line class="cls-6374f8d9b67f094e4896c676-1" x1="19.64" y1="8.18" x2="15.82" y2="4.36"></line></svg>
        <svg xmlns="http://www.w3.org/2000/svg" class="icon description" viewBox="0 0 24 24" stroke-width="1.5" width="24" height="24"><title>${t.descMap}</title><defs><style>.cls-6374f8d9b67f094e4896c63c-1{fill:none;stroke:currentColor;stroke-miterlimit:10;}</style></defs><circle class="cls-6374f8d9b67f094e4896c63c-1" cx="12" cy="12" r="10.5"></circle><polyline class="cls-6374f8d9b67f094e4896c63c-1" points="12 16.77 12 10.09 10.09 10.09"></polyline><line class="cls-6374f8d9b67f094e4896c63c-1" x1="10.09" y1="16.77" x2="13.91" y2="16.77"></line><line class="cls-6374f8d9b67f094e4896c63c-1" x1="11.05" y1="7.23" x2="12.95" y2="7.23"></line></svg>
        <svg xmlns="http://www.w3.org/2000/svg" class="share icon"viewBox="0 0 24 24" stroke-width="1.5" width="24" height="24"><title>${t.share}</title><defs><style>.cls-6374f8d9b67f094e4896c662-1{fill:none;stroke:currentColor;stroke-miterlimit:10;}</style></defs><circle class="cls-6374f8d9b67f094e4896c662-1" cx="19.64" cy="4.36" r="2.86"></circle><circle class="cls-6374f8d9b67f094e4896c662-1" cx="4.36" cy="12" r="2.86"></circle><circle class="cls-6374f8d9b67f094e4896c662-1" cx="19.64" cy="19.64" r="2.86"></circle><line class="cls-6374f8d9b67f094e4896c662-1" x1="17.08" y1="5.64" x2="6.92" y2="10.72"></line><line class="cls-6374f8d9b67f094e4896c662-1" x1="17.08" y1="18.36" x2="6.92" y2="13.28"></line></svg>
        <svg xmlns="http://www.w3.org/2000/svg" class="like ${like.current ? 'liked' : ''} ${props.user && !userMap.current.draft ? 'icon' : ''}" viewBox="0 0 24 24" stroke-width="1.5" width="24" height="24"><title>${t.like}</title><defs><style>.cls-637b7d2ff95e86b59c579f9b-1{fill:none;stroke:currentColor;stroke-miterlimit:10;}</style></defs><path class="cls-637b7d2ff95e86b59c579f9b-1 heart" d="M16.77,3.41A5.73,5.73,0,0,0,12,6,5.73,5.73,0,0,0,1.5,9.14C1.5,17.73,12,20.59,12,20.59S22.5,17.73,22.5,9.14A5.72,5.72,0,0,0,16.77,3.41Z"></path><path class="cls-637b7d2ff95e86b59c579f9b-1 heart" d="M16.77,7.23a1.9,1.9,0,0,1,1.91,1.91,6.25,6.25,0,0,1-1.95,4.43"></path></svg>
        <span class="likeNum">${numToK(numOfLike.current)}</span>
      </div>
      </div>`;

    interval = null;
    afterEffect = titleEventHandle;
  } else if (index === 'd') {
    // Description
    let image = userMap.current.image ? `<img src="${userMap.current.image}"/>` : '';
    element = `<div class="descFlap" title="${sanitaize(userMap.current.description, '')}">
      <div class="imgContainer">${image}</div>
      <p style="top: ${descPage * -100}px;">${sanitaize(userMap.current.description, '', true)}</p>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="2" width="24" height="24"><title>${t.next}</title><defs><style>.cls-63ce7424ea57ea6c8380057b-1{fill:none;stroke:currentColor;stroke-miterlimit:10;}</style></defs><line class="cls-63ce7424ea57ea6c8380057b-1" x1="12" y1="21.5" x2="12" y2="0.5"></line><polyline class="cls-63ce7424ea57ea6c8380057b-1" points="4.36 13.86 12 21.5 19.64 13.86"></polyline></svg>
    </div>`;
    afterEffect = descEventHandle;
    interval = null;
  } else if (index === 'l') {
    // Loading
    element = `<div class="loadingFlap">
    <p>Loading....</p>
    </div>`;
    repeat = true;
  } else {
    // Each items
    removePopup(props.map.current);
    const stdt = userMap.current.stDate ? new Date(userMap.current.stDate + "T00:00") : null;
    const pos = userMap.current.features[index];
    const tm = pos.properties.time ? new Date("1970-01-01T" + pos.properties.time) : null;
    let dt;
    if (userMap.current.stDate && pos.properties.day && pos.properties.time) {
      stdt.setDate(stdt.getDate() + (pos.properties.day - 1));
      dt = `${t.dFormater(stdt)}<br/>${t.tmFormater(tm)}`
    } else if (userMap.current.stDate && pos.properties.day) {
      stdt.setDate(stdt.getDate() + (pos.properties.day - 1));
      dt = t.dFormater(stdt);
    } else if (pos.properties.day && pos.properties.time) {
      dt = `${t.dayFormatter(pos.properties.day)}<br/>${t.tmFormater(tm)}`;
    } else if (userMap.current.stDate && pos.properties.time) {
      dt = `${t.dFormater(stdt)}<br/>${t.tmFormater(tm)}`;
    } else if (userMap.current.stDate) {
      dt = t.dFormater(stdt);
    } else if (pos.properties.day) {
      dt = t.dayFormatter(pos.properties.day);
    } else if (pos.properties.time) {
      dt = t.tmFormater(tm);
    } else {
      dt = '';
    }

    if (pos.properties.description) {
      const popup = new mapboxgl.Popup(
        {
          closeButton: false, 
          className: 'popup', 
          maxWidth: isMobile() ? '90%' : '300px', 
          focusAfterOpen: false
        })
        .setLngLat(pos.geometry.coordinates)
        .setHTML(sanitaize(pos.properties.description,'',true))
        .addTo(props.map.current);
      props.map.current.once('closeAllPopups', () => popup.remove());
    }

    if (pos.properties.image) {
      const storage = getStorage();
      const imageFile = pos.properties.image;
      let imageBlob = imageCache.current[imageFile];
      if (imageBlob)
        makeImagePopup(pos.geometry.coordinates, imageBlob, props.map.current);
      else {
        popupImageFile.current = imageFile;
        getBlob(ref(storage, `user/${userMap.current.uid}/maps/${userMap.current.id}/${imageFile}`), 1024*1024).then((blob) => {
          if (popupImageFile.current === imageFile)
            makeImagePopup(pos.geometry.coordinates, blob, props.map.current);
          imageCache.current[imageFile] = blob;
        }).catch((error) => {
          console.error('image not found: ' + imageFile);
        });
      }
    }
    const event = pos.properties.event ? EventIcon[pos.properties.event].element : "#" + pos.id;

    let navArrow = '';
    const prevIcon = `<span class="backNav"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5" width="24" height="24"><title>${t.prev}</title><defs><style>.cls-63ce7424ea57ea6c8380054e-1{fill:none;stroke:currentColor;stroke-miterlimit:10;}</style></defs><circle class="cls-63ce7424ea57ea6c8380054e-1" cx="12" cy="12" r="10.5"></circle><line class="cls-63ce7424ea57ea6c8380054e-1" x1="17.73" y1="12" x2="6.27" y2="12"></line><polyline class="cls-63ce7424ea57ea6c8380054e-1" points="11.04 16.77 6.27 12 11.04 7.23"></polyline></svg></span>`;
    const nextIcon = `<span class="forwardNav"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5" width="24" height="24"><title>${t.next}</title><defs><style>.cls-63ce7424ea57ea6c8380054f-1{fill:none;stroke:currentColor;stroke-miterlimit:10;}</style></defs><circle class="cls-63ce7424ea57ea6c8380054f-1" cx="12" cy="12" r="10.5"></circle><line class="cls-63ce7424ea57ea6c8380054f-1" x1="6.27" y1="12" x2="17.73" y2="12"></line><polyline class="cls-63ce7424ea57ea6c8380054f-1" points="12.96 7.23 17.73 12 12.96 16.77"></polyline></svg></span>`;
    if (flyState.current !== 'play')
      navArrow = `
        ${index === 0 ? '' : prevIcon}
        ${index === userMap.current.features.length - 1 ? '' : nextIcon}
        <a href="https://maps.google.com/maps?q=${pos.geometry.coordinates[1]},${pos.geometry.coordinates[0]}" class="linkGglMap" target=”_blank” />${t.openGoogleMap}</a>`;

    element = `<div class="item">
      <div class="number" ${pos.properties.event ? 'title="' + t.event[pos.properties.event] +'"' : ''}>${event}</div>
      <div class="dt">${dt}</div>
      <div class="name">${sanitaize(pos.properties.name, t.noTitle)}</div>
      ${navArrow}
      </div>`;
    if (flyState.current === 'play')
      interval = pos.properties.transport in TransportIcon ? pos.properties.transport : 'toboggan';
    else
      interval = null;
    afterEffect = itemEventHandle;
  }

  useEffect(() => {
    
    const mapContainerStyle = props.mapContainer.current.style;
    let map;

    if (mapContainerStyle.display !== 'block') {
      mapContainerStyle.display = 'block';
      mapContainerStyle.position = 'absolute';
      mapContainerStyle.width = '100vw';
      mapContainerStyle.height = '100%';
      mapContainerStyle.top = 0;
      mapContainerStyle.left = 0;
  
      if (props.map.current) {
        map = props.map.current;
        map.resize();
        changeMapStyle(map, mapStyle, (mode) => {
          setItemLayer(null, mode, true);
          setMapLoading(false);
        }, true);
      } else {
        newMap(props, mapStyle, allBounds.current);
        map = props.map.current;
        map.on('load', () => {
          changeMapStyle(map, mapStyle, (mode) => {
            setItemLayer(() => 
            {
              if (searchParams.get('auto') === 'true')
                autoFly();
              setMapLoading(false);
            }, mode, true);
          }, true);
        });
      }

    } else {
      map = props.map.current;
      setMapLoading(false);
    }

    map.on('click', 'makers', focusMark);
    map.on('click', resetMap);
    window.addEventListener('blur', forceStop);
    map.on('mouseenter', 'makers', enterMark);
    map.on('mouseleave', 'makers', leaveMark);

    return () => {
      map.off('click', 'makers', focusMark);
      map.off('click', resetMap);
      map.off('mouseenter', 'makers', enterMark);
      map.off('mouseleave', 'makers', leaveMark);
  
      window.removeEventListener('blur', forceStop);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (flyState.current === 'play') {
      let pos = userMap.current.features[index];
      setCenter(pos.geometry.coordinates, props.map.current.getZoom() < 16 ? 16 : props.map.current.getZoom());
      if ((reverse.current && index > 0) ||
        (!reverse.current && index < userMap.current.features.length - 1)) {
          props.map.current.once('moveend', () => 
        {
          if (flyState.current === 'play') 
              toId.current = setTimeout(() => setIndex(reverse.current ? index - 1 : index + 1), 2000);
        }
        );
      } else {
        props.map.current.once('moveend', () => {
          if (flyState.current === 'play') 
            toId.current = setTimeout(() => stop(), 2000);
        });
      }
    }
  }, [flyState, index, props.map, stop, setCenter]);

  const focusMark = (e) => {
    if (flyState.current !== 'play') {
      const i = userMap.current.features.findIndex(item => item.id === e.features[0].id);
      setIndex(i);
      setCenter(e.features[0].geometry.coordinates);
    }
    e.clickOnLayer = true;
  }

  const forceStop = () => {
    if (flyState.current === "play")
      stop();
  }

  const enterMark = (e) => {
    const map = props.map.current;
    map.getCanvas().style.cursor = 'pointer';
    if (e.features.length > 0) {
      if (hoveredPointId.current) {
        map.setFeatureState(
          { source: MAP_ID.data, id: hoveredPointId.current },
          { hover: false }
        );
      }
      hoveredPointId.current = e.features[0].id;
      map.setFeatureState(
        { source: MAP_ID.data, id: hoveredPointId.current },
        { hover: true }
      );
    }
  };

  const leaveMark = (e) => {
    const map = props.map.current;
    map.getCanvas().style.cursor = '';
    if (hoveredPointId.current) {
      map.setFeatureState(
        { source: MAP_ID.data, id: hoveredPointId.current },
        { hover: false }
      );
    }
    hoveredPointId.current = null;
  };

  const resetMap = (e) => {
    if (!e.clickOnLayer)
      setIndex('t');
    if (flyState.current !== 'stop')
      stop();
  }

  function setItemLayer(callback, mode, moveCenter) {
    const map = props.map.current;

    mapReset(map);

    map.loadImage(
      '../pin.png',
      (error, image) => {
        if (error) throw error;
        map.addImage(MAP_ID.image, image, { sdf: true });
        map.addSource(MAP_ID.data, {
          'type': 'geojson',
          'data': userMap.current
        });
        map.addLayer({
          'id': MAP_ID.layer,
          'type': 'symbol',
          'source': MAP_ID.data,
          'layout': {
            'icon-image': 'pin-marker',
            'text-field': ["concat", ["id"], '. ', ['coalesce', ['get', 'name'], t.noTitle]],
            'text-offset': [0, 0.5],
            'text-anchor': 'top',
            'text-size': 20,
            'text-max-width': 20,
            'text-allow-overlap': true,
            'icon-allow-overlap': true,
            "icon-anchor": 'bottom',
          },
          paint: {
            "icon-color": [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              STYLE.COLOR_THEME[mode].COLOR_ON_SURFACE,
              STYLE.COLOR_THEME[mode].COLOR_PRIMARY
            ],
            "icon-opacity": [
              'case',
              ['boolean', ['feature-state', 'notSelected'], false],
              0.4,
              1
            ],
            "text-color": [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              STYLE.COLOR_THEME[mode].COLOR_ON_SURFACE,
              STYLE.COLOR_THEME[mode].COLOR_PRIMARY
            ],
            "text-opacity": [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              1,
              ['case', ['boolean', ['feature-state', 'notSelected'], false], 0.2, 0.9]
            ],
            "text-halo-blur": 3,
            "text-halo-color": STYLE.COLOR_THEME[mode].COLOR_PRIMARY_CONTAINER,
            "text-halo-width": 2
          }
        });
        
        const lineItems = getLineItems(userMap.current);
        if (lineItems.length > 0) {
          map.addSource(MAP_ID.lineData, {
            type: 'geojson',
            data: {
              "type": "FeatureCollection",
              "name": "line",
              "features": lineItems
            },
          });
          map.addLayer({
            'id': MAP_ID.lineLayer,
            'type': 'line',
            'source': 'line',
            'layout': {
              'line-cap': 'round',
              'line-join': 'round'
            },
            paint: {
              'line-width': 2,
              'line-blur': 2,
              'line-gap-width': 3,
              'line-color': [
                'case',
                ['boolean', ['feature-state', 'notYet'], false],
                STYLE.COLOR_THEME[mode].COLOR_ON_BACKGROUND,
                STYLE.COLOR_THEME[mode].COLOR_SECONDARY
              ]
            }
          }, MAP_ID.layer);
          map.addLayer({
            'id': MAP_ID.lineTitle,
            'type': 'symbol',
            'source': 'line',
            'layout': {
              'text-field': ['get', 'title'],
              "symbol-placement": "line-center",
              'text-size': 20,
            },
            paint: {
              'text-color': STYLE.COLOR_THEME[mode].COLOR_SECONDARY,
              "text-halo-blur": 5,
              "text-halo-color": STYLE.COLOR_THEME[mode].COLOR_SECONDARY_CONTAINER,
              "text-halo-width": 3,
            }
          }, MAP_ID.layer);
        }
        if (callback)
          callback();
    }
    );
    if (moveCenter)
      map.fitBounds(allBounds.current, {padding: getBoundPadding(), linear: true});
  }

  function removePopup(map) {
    if (map)
      map.fire('closeAllPopups');
  }

  function autoFly() {
    if (flyState.current === 'stop')
      setIndex(initialVal.current);
    else if (flyState.current === 'pause') {
      if ((reverse.current && index > 0) ||
          (!reverse.current && index < userMap.current.features.length - 1))
        setIndex(reverse.current ? index - 1 : index + 1);
      else {
        stop();
        return;
      }
    }
    setFlyState('play');
    flyMapId.current = userMap.current.id;
  }

  function getBoundPadding() {
    const mapElement = document.querySelector('.mapboxgl-canvas');
    if (!mapElement)
      return null;
    if (isMobile() || isEmbedded) {
      if (mapElement.clientWidth < 20)
        return null;
      if (mapElement.clientHeight < 270)
        return null;
      return {top: 220, bottom: 50, left: 10, right: 10};
    } else {
      if (mapElement.clientWidth < 530)
        return null;
      if (mapElement.clientHeight < 100)
        return null;
      return {top: 50, bottom: 50, left: 490, right: 40};
    }
  }

  function makeImagePopup(lngLat, imageBlob, map) {
    const popup = new mapboxgl.Popup({
      closeButton: false, 
      anchor:'top', 
      className: 'popupImg', 
      offset: 40, 
      maxWidth: '350px', 
      focusAfterOpen: false
    })
    .setLngLat(lngLat)
    .setHTML(`<img src="${URL.createObjectURL(imageBlob)}"/>`)
    .addTo(map);
    map.once('closeAllPopups', () => popup.remove());
  }

  const overlayStyle = {
    position: "absolute",
    top: isMobile() ? "0" : "5px",
    left: isMobile() ? "0" : "5px",
    width: isMobile() ? "100vw" : "470px"
  };

  const controllerStyle = {
    position: "absolute",
    top: isMobile() ? "115px" : "110px",
    display: "flex"
  };

  const stylePlayerOuterStyle = {
    ...controllerStyle,
    right: isMobile() ? "2vw" : "0",
    gap: '0 4px'
  }

  const playerStyle = {
    borderRadius: "10px",
    padding: "8px",
    backgroundColor: STYLE.COLOR_PRIMARY_CONTAINER,
    border: `solid 1px ${STYLE.COLOR_ON_PRIMARY_CONTAINER}`,
    display: "flex"
  };

  const playerIconStyle = {
  };

  const playDescStyle = {
    color: STYLE.COLOR_ON_PRIMARY_CONTAINER,
    display: flyState.current === 'stop' ? "initial" : "none"
  };

  const playerIconPauseStyle = {
    ...playerIconStyle,
    display: flyState.current === 'play' ? "initial" : "none"
  };

  const playerIconStopStyle = {
    ...playerIconStyle,
    display: flyState.current === 'stop' ? "none" : "initial",
    marginLeft: "0.5em"
  };

  const playerIconPlayStyle = {
    ...playerIconStyle,
    display: flyState.current === 'play' ? "none" : "initial",
    marginLeft: flyState.current === 'stop' && !isMobile() ? "0.5em" : "initial"
  };

  const logoButtonStyle = {
    ...controllerStyle,
    height: "42px",
    borderRadius: "10px",
    backgroundColor: STYLE.COLOR_SURFACE_VARIANT,
    padding: "0 0 2px",
    width: "125px",
    left: isMobile() ? "2vw" : "0",
    border: 'none',
    cursor: 'pointer',
  }

  const backContainerStyle = {
    ...controllerStyle,
    height: "42px",
    borderRadius: "10px",
    backgroundColor: STYLE.COLOR_SURFACE_VARIANT,
    width: "35px",
    left: isMobile() ? "calc(2vw + 128px)" : "128px",
  }

  const logoStyle = {
    width: '133px',
    transform: 'rotate(-10deg)',
    position: 'relative',
    left: '-5px',
    top: '1px',
  };

  const backIconStyle = {
    strokeWidth: 1.5,
    margin: "10px 5px",
  };


  return (
    <>
      <style>{`
        .icon {
          color: ${STYLE.COLOR_ON_PRIMARY_CONTAINER};
        }
        .icon.hide {
          display: none;
        }
        .icon:hover {
          color: ${STYLE.COLOR_ON_BACKGROUND};
          cursor: pointer;
        }
        .mapboxgl-popup {
          min-width: 300px
        }
        .popup > div:nth-child(2) {
          background-color: ${STYLE.COLOR_SECONDARY};
          color: ${STYLE.COLOR_ON_SECONDARY};
          font-size: 150%;
          padding: 10px;
          border-radius: 10px;
          white-space: pre-line;
          word-break: break-word;
        }
        .popup > div:nth-child(2) a {
          color: ${STYLE.COLOR_ON_SECONDARY};
        }
        .popup > div:nth-child(2) a:focus {
          outline: none;
        }
        .popup > div:nth-child(1) {
          border-top-color: ${STYLE.COLOR_SECONDARY};
        }
        div.popupImg > div {
          background-color: initial;
          text-align: center;
        }
        div.popupImg > .mapboxgl-popup-tip {
          border-bottom-color: transparent;
        }
        div.popupImg img {
          border: 5px solid white;
          max-height:300px; 
          max-width:300px;
        }
        .flap > div {
          display: grid;
          overflow: hidden; 
          align-items: center;
          height: 100%;
        }
        .flap > div > div {
          overflow: hidden; 
          text-overflow: ellipsis;
          padding: 0 10px;
        }
        div.overview {
          grid-template-rows: 2fr 1fr 1fr;
          grid-template-columns: 1fr 1fr;
          grid-template-areas:"title title" "auther operation" "dt operation";
        }
        div.overview .title {
          grid-area: title;
          font-size: 20px; 
        }
        div.overview .auther {
          grid-area: auther;
          font-weight: bold;
          font-size: 16px;
          color: ${STYLE.COLOR_ON_SECONDARY_CONTAINER};
        }
        div.overview .dt {
          grid-area: dt;
          font-size: 14px;
        }
        div.overview .auther .id:hover {
          color: ${STYLE.COLOR_ON_BACKGROUND};
          cursor: pointer;
        }
        div.overview .operation {
          grid-area: operation;
          text-align: right;
        }
        div.overview .operation .liked .heart {
          fill: ${STYLE.COLOR_ERROR};
          stroke: ${STYLE.COLOR_ON_BACKGROUND};
        }
        div.overview .operation .liked:hover .heart {
          opacity: 0.7;
        }
        div.item {
          grid-template-rows: 1fr 1fr;
          grid-template-columns: auto 1fr;
          grid-template-areas:"number dt" "name name";
        }
        div.item .number {
          grid-area: number;
          font-size: 18px; 
        }
        div.item .number svg {
          color: ${STYLE.COLOR_SECONDARY};
          width: 36px;
          height: 36px;
        }
        div.item .name {
          grid-area: name;
          font-size: 20px; 
        }
        div.item a.linkGglMap {
          opacity: 0.6;
          position:absolute; 
          right:5px; 
          top:33px; 
          font-size:11px; 
          color:${STYLE.COLOR_ON_PRIMARY_CONTAINER}; 
          text-decoration: none;
        }
        div.item a.linkGglMap:hover {
          color: ${STYLE.COLOR_ON_BACKGROUND};
        }
        div.item .dt {
          grid-area: dt;
          font-size: 18px;
          line-height: 1.1;
        }
        div.descFlap {
          display: flex;
          align-items: center;
        }
        div.descFlap .imgContainer {
          width: 145px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          margin: 0;
          padding: 0 5px;
        }
        div.descFlap img {
          max-height: 90px;
          max-width: 145px;
        }
        div.descFlap p {
          white-space: pre-wrap;
          margin: 0 10px 0 5px;
          align-self: flex-start;
          overflow: hidden;
          font-size: 16px;
          line-height: 25px;
          position: relative;
          overflow-wrap: anywhere;
        }
        div.descFlap p a{
          color: ${STYLE.COLOR_ON_BACKGROUND};
        }
        div.descFlap svg, div.item span.backNav svg, div.item span.forwardNav svg {
          color: ${STYLE.COLOR_ON_PRIMARY_CONTAINER};
          opacity: 0.4;
          position:absolute;
        }
        div.item span.backNav svg, div.item span.forwardNav svg {
          width: 25px;
          height: 25px;
          top: 3px;
        }
        div.descFlap svg {
          width: 16px;
          height: 16px;
          right: -2px;
          bottom: 2px;
        }
        div.item span.forwardNav svg {
          right: 5px;
        }
        div.item span.backNav svg {
          right: 35px;
        }
        div.descFlap svg:hover, div.item span.backNav svg:hover, div.item span.forwardNav svg:hover {
          cursor: pointer;
          color: ${STYLE.COLOR_ON_BACKGROUND};
          opacity: 0.8;
        }
        div.flap > div.interval {
          position: absolute;
        }
        div.loadingFlap {
          text-align: center;
          font-size: 200%;
          margin: 0 0 10px;
        }
       `}</style>
      <div id="overlay" style={overlayStyle}>
        <Flap element={element} 
          interval={interval} 
          afterEffect={afterEffect} 
          repeat={repeat}
          dummyCounter={props.dummyCounter}
          />
        <button style={logoButtonStyle}
          onClick={() => {
            if (isEmbedded)
              window.open('/', 'toboggar')
            else {
              stop();
              setIndex('l');
              moveToTop(navigate);
            }
        }}>
          <img style={logoStyle} alt="Toboggar" src="/logo.svg" />
        </button>
        <div style={backContainerStyle} >
          <Icon.Back className="icon" style={backIconStyle} title={t.back} onClick={() => {
              stop();
              setIndex('l');
              navigate((location.state?.back) ? -1 : '/');
            }}/>
        </div>
        <div style={stylePlayerOuterStyle}>
          <SwitchStyle 
            map={props.map.current}
            onLoad={(mode) => setItemLayer(null, mode)}
            default={mapStyle}
            loading={mapLoading}
            style={{height: '42px'}}
          />
          <div style={playerStyle}>
            {isMobile() ? null : <span style={playDescStyle}>Auto Play</span>}
            <Icon.Play title={t["play"]} className="icon" style={playerIconPlayStyle} onClick={autoFly}/>
            <Icon.Pause title={t["pause"]} className="icon" style={playerIconPauseStyle} onClick={() => {
                clearTimeout(toId.current);
                flyMapId.current = null;
                setFlyState('pause');
              }}/>
            <Icon.Stop title={t["stop"]} className="icon" style={playerIconStopStyle} onClick={() => {
                stop();
              }}/>
          </div>
        </div>
      </div>
      <AddMap dummyCounter={props.dummyCounter} isEmbedded={isEmbedded} />
      <Share shareDlg={shareDlg} setShareDlg={setShareDlg} userMap={userMap.current} />
    </>
  );
}

function setUserMapHeader(userMap, t) {
  setHeaderInfo(`${userMap.title ? userMap.title : t.noTitle} - Toboggar`, userMap.description, userMap.lang);
}

function LoaderHandle(user) {
  return async ({request, params}) => {
    const res = {userMap: null, like: false};
    const getLike = user ? getDoc(doc(db, `maps/${params.mapId}/likes`, user.uid)) : null; 
    const query = [getDoc(doc(db, "maps", params.mapId)), getLike];
  
    await Promise.all(query).then(docSnap => {
      if (docSnap[0].exists()){
        res.userMap = {...docSnap[0].data(), id: params.mapId};
        if (docSnap[1]?.exists())
          res.like = true;
      }
      else
        throw new Response("Not Found", { status: 404 });
    }).catch((error) => {
      if (error.code === 'permission-denied')
        throw new Response("Not Found", { status: 404 });
      throw new Response(error.name + ' ' + error.message, { status: 403 });
    });
    return res;
  };
}

async function LoaderForBot ({request, params}){
  // bypass App check for bot
  const res = await fetch(`${process.env.REACT_APP_FB_FC_DOMAIN}/botProxy/m/${params.mapId}`);
  if (!res.ok)
    throw new Response('Error', { status: res.status });
  const json = await res.json();
  json.userMap.published = new Timestamp(json.userMap.published._seconds, json.userMap.published._nanoseconds);
  return json;
}

function ElementForBot(props) {
  const t = window.t;
  const userMap = useLoaderData().userMap;

  setUserMapHeader(userMap, t);
  let dt = userMap.draft ? null : userMap.published.toDate();

  return (
    <>
      <h4 className="title" title={userMap.title}>{userMap.title}</h4>
      <div className="auther"><span className="id">{userMap.auther}</span></div>
      <div className="dt">{dt ? t.dtFormater(dt) : t.draft}</div>
      {userMap.image ? <img alt={userMap.title} src={userMap.image}/> : null}
      <p className="descFlap" title={userMap.description}>
        {userMap.description}
      </p>
      <ul>
        {userMap.features.map((item) => {
          return <li key={item.id}>
            <h5>{item.properties.name}</h5>
            <p>{item.properties.description}</p>
          </li>;
        })}
      </ul>
    </>
  );
}

const UserMap = {
  Element,
  LoaderHandle,
  ElementForBot,
  LoaderForBot
};

Element.propTypes = {
  user: PropTypes.object,
  mapContainer: PropTypes.object,
  map: PropTypes.object,
  dummyCounter: PropTypes.number
}

ElementForBot.propTypes = {
}

export default UserMap;
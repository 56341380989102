/* --md-source: #80CBC4; #FFCC80; */

const COLOR_THEME = {
  dark: {
    COLOR_PRIMARY : '#4fdbd1',
    COLOR_ON_PRIMARY : '#003734',
    COLOR_PRIMARY_CONTAINER : '#00504b',
    COLOR_ON_PRIMARY_CONTAINER : '#71f7ed',
    
    COLOR_SECONDARY : '#fdba4b',
    COLOR_ON_SECONDARY : '#442c00',
    COLOR_SECONDARY_CONTAINER : '#614000',
    COLOR_ON_SECONDARY_CONTAINER : '#ffddb0',
    
    COLOR_TERTIARY : '#afc9e7',
    COLOR_ON_TERTIARY : '#18324a',
    COLOR_TERTIARY_CONTAINER : '#304962',
    COLOR_ON_TERTIARY_CONTAINER : '#cfe5ff',
    
    COLOR_SURFACE : '#191c1c',
    COLOR_ON_SURFACE : '#e0e3e2',
    
    COLOR_ERROR : '#ffb4ab',
    COLOR_ON_ERROR : '#690005',
    COLOR_ERROR_CONTAINER : '#93000a',
    COLOR_ON_ERROR_CONTAINER : '#ffdad6',
    COLOR_BACKGROUND : '#191c1c',
    COLOR_ON_BACKGROUND : '#e0e3e2',
    COLOR_OUTLINE : '#899391',
    COLOR_SURFACE_VARIANT : '#3f4947',
    COLOR_ON_SRFACE_VARIANT : '#bec9c7',
  },
  light: {
    COLOR_PRIMARY : '#006a64',
    COLOR_ON_PRIMARY : '#ffffff',
    COLOR_PRIMARY_CONTAINER : '#71f7ed',
    COLOR_ON_PRIMARY_CONTAINER : '#00201e',
    
    COLOR_SECONDARY : '#805600',
    COLOR_ON_SECONDARY : '#ffffff',
    COLOR_SECONDARY_CONTAINER : '#ffddb0',
    COLOR_ON_SECONDARY_CONTAINER : '#281800',
    
    COLOR_TERTIARY : '#48617b',
    COLOR_ON_TERTIARY : '#ffffff',
    COLOR_TERTIARY_CONTAINER : '#cfe5ff',
    COLOR_ON_TERTIARY_CONTAINER : '#001d34',
    
    COLOR_SURFACE : '#fafdfb',
    COLOR_ON_SURFACE : '#191c1c',
    
    COLOR_ERROR : '#ba1a1a',
    COLOR_ON_ERROR : '#ffffff',
    COLOR_ERROR_CONTAINER : '#ffdad6',
    COLOR_ON_ERROR_CONTAINER : '#410002',
    COLOR_BACKGROUND : '#fafdfb',
    COLOR_ON_BACKGROUND : '#191c1c',
    COLOR_OUTLINE : '#6f7977',
    COLOR_SURFACE_VARIANT : '#dae5e2',
    COLOR_ON_SRFACE_VARIANT : '#3f4947',
  }
}



const COLOR_PRIMARY = COLOR_THEME.dark.COLOR_PRIMARY;
const COLOR_ON_PRIMARY = COLOR_THEME.dark.COLOR_ON_PRIMARY;
const COLOR_PRIMARY_CONTAINER = COLOR_THEME.dark.COLOR_PRIMARY_CONTAINER;
const COLOR_ON_PRIMARY_CONTAINER = COLOR_THEME.dark.COLOR_ON_PRIMARY_CONTAINER;

const COLOR_SECONDARY = COLOR_THEME.dark.COLOR_SECONDARY;
const COLOR_ON_SECONDARY = COLOR_THEME.dark.COLOR_ON_SECONDARY;
const COLOR_SECONDARY_CONTAINER = COLOR_THEME.dark.COLOR_SECONDARY_CONTAINER;
const COLOR_ON_SECONDARY_CONTAINER = COLOR_THEME.dark.COLOR_ON_SECONDARY_CONTAINER;

const COLOR_TERTIARY = COLOR_THEME.dark.COLOR_TERTIARY;
const COLOR_ON_TERTIARY = COLOR_THEME.dark.COLOR_ON_TERTIARY;
const COLOR_TERTIARY_CONTAINER = COLOR_THEME.dark.COLOR_TERTIARY_CONTAINER;
const COLOR_ON_TERTIARY_CONTAINER = COLOR_THEME.dark.COLOR_ON_TERTIARY_CONTAINER;

const COLOR_SURFACE = COLOR_THEME.dark.COLOCOLOR_SURFACER_PRIMARY;
const COLOR_ON_SURFACE = COLOR_THEME.dark.COLOR_ON_SURFACE;

const COLOR_ERROR = COLOR_THEME.dark.COLOR_ERROR;
const COLOR_ON_ERROR = COLOR_THEME.dark.COLOR_ON_ERROR;
const COLOR_ERROR_CONTAINER = COLOR_THEME.dark.COLOR_ERROR_CONTAINER;
const COLOR_ON_ERROR_CONTAINER = COLOR_THEME.dark.COLOR_ON_ERROR_CONTAINER;
const COLOR_BACKGROUND = COLOR_THEME.dark.COLOR_BACKGROUND;
const COLOR_ON_BACKGROUND = COLOR_THEME.dark.COLOR_ON_BACKGROUND;
const COLOR_OUTLINE = COLOR_THEME.dark.COLOR_OUTLINE;
const COLOR_SURFACE_VARIANT = COLOR_THEME.dark.COLOR_SURFACE_VARIANT;
const COLOR_ON_SRFACE_VARIANT = COLOR_THEME.dark.COLOR_ON_SRFACE_VARIANT;


const MAPBOX_STYLE = {
  street: {
    name: 'Mapbox Streets',
    uri: 'mapbox://styles/mapbox/streets-v12',
    mode: 'light'
  },
  satellite : {
    name: 'Mapbox Satellite',
    uri: 'mapbox://styles/mapbox/satellite-v9',
    mode: 'dark'
  },
  simple: {
    name: 'Mapbox Dark',
    uri: 'mapbox://styles/mapbox/dark-v11',
    mode: 'dark'
  },
}

export {
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  COLOR_SURFACE,
  COLOR_ERROR,
  COLOR_ON_PRIMARY,
  COLOR_ON_SECONDARY,
  COLOR_ON_SURFACE,
  COLOR_ON_ERROR,
  COLOR_PRIMARY_CONTAINER,
  COLOR_ON_PRIMARY_CONTAINER,
  COLOR_SECONDARY_CONTAINER,
  COLOR_ON_SECONDARY_CONTAINER,
  COLOR_TERTIARY,
  COLOR_ON_TERTIARY,
  COLOR_TERTIARY_CONTAINER,
  COLOR_ON_TERTIARY_CONTAINER,
  COLOR_ERROR_CONTAINER,
  COLOR_ON_ERROR_CONTAINER,
  COLOR_BACKGROUND,
  COLOR_ON_BACKGROUND,
  COLOR_OUTLINE,
  COLOR_SURFACE_VARIANT,
  COLOR_ON_SRFACE_VARIANT,
  MAPBOX_STYLE,
  COLOR_THEME,
};
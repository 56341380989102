import { useLoaderData } from "react-router-dom";
import { useState, useRef, useCallback } from 'react';
import { deleteUser, unlink, linkWithPopup, reauthenticateWithPopup, GoogleAuthProvider, TwitterAuthProvider } from "firebase/auth";
import { getDoc, doc, updateDoc } from "firebase/firestore"; 
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import {setHeaderInfo, db, isMobile} from "../common/Util";
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import * as STYLE from "../common/Style";
import {Icon} from "../common/Icon";
import ImageLoader from "../common/ImageLoader";
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import PropTypes from 'prop-types';
import Header from "./Header";
import i18n from '../common/I18n'

function Element(props) {

  const [authError, setAuthError] = useState();
  const [reAuth, setReAuth] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const PROVIDER_ID_GOOGLE = 'google.com';
  const PROVIDER_ID_X = 'twitter.com';
  const [edit, setEdit] = useState(false);
  const nameRef = useRef(null);
  const profileRef = useRef(null);
  const [nameError, setNameError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState(null);
  const [lang, setLang] = useState();
  const [image, setImage] = useState();

  const t = window.t;
  const user = useLoaderData();
  setHeaderInfo(`Profile - Toboggar`, null, t.lang);

  const moreThen2Provider = props.user.providerData.length > 1;

  const linkedProviders = new Map();
  props.user.providerData.forEach(element => linkedProviders.set(element.providerId, true));
  const [linked, setLinked] = useState(linkedProviders);

  function makeReLoginHandle(provider) {
    return () => {
      reauthenticateWithPopup(props.user, provider)
      .then((result) => {
        deleteUser(props.user).then(() => {
          window.location.href = "/";
        }).catch((error) => {
          setAuthError(error);
          setReAuth(false);
        });
      }).catch((error) => {
        if (error.code === 'auth/popup-closed-by-user')
          return;
        if (error.code === 'auth/user-cancelled')
          return;
        if (error.code === 'auth/popup-blocked')
          error.message = t.poupBlocked;
        setAuthError(error);
        setReAuth(false);
      });
    }
  }

  function makeLinkHandle(provider) {
    return ()=> {
      setLoading(true);
      linkWithPopup(props.user, provider).then((result) => {
        linked.set(provider.providerId, true);
        setLinked(new Map(linked));
        setLoading(false);
      }).catch((error) => {
        if (error.code === 'auth/popup-closed-by-user')
          return;
        if (error.code === 'auth/user-cancelled')
          return;
        if (error.code === 'auth/credential-already-in-use')
          error.message = t.alreadyLinked;
        if (error.code === 'auth/popup-blocked')
          error.message = t.poupBlocked;
        setAuthError(error);
        setLoading(false);
      });
    }
  }

  function makeUnlinkHandle(providerId) {
    return () => {
      setLoading(true);
      unlink(props.user, providerId).then(() => {
        setAuthError(null);
        linked.set(providerId, false);
        setLinked(new Map(linked));
        setLoading(false);
      }).catch((error) => {
        setAuthError(error);
        setLoading(false);
      });
    }
  }

  const resizeImage = useCallback((image) => {
    const context = document.createElement('canvas').getContext('2d');
    const MAX_LENGTH = 150;
    let img = new Image();
    img.addEventListener(
      "load",
      () => {
        const { naturalHeight: beforeHeight, naturalWidth: beforeWidth } = img;
        const isLandscape = beforeHeight < beforeWidth;
        const needResize = (isLandscape && beforeWidth > MAX_LENGTH)
          || (!isLandscape && beforeHeight > MAX_LENGTH);
        if (needResize) {
          const sx = isLandscape ? Math.floor((beforeWidth - beforeHeight) / 2) : 0;
          const sy = isLandscape ? 0 : Math.floor((beforeHeight - beforeWidth) / 2);
          const sLength = isLandscape ? beforeHeight : beforeWidth;
          context.canvas.width = MAX_LENGTH;
          context.canvas.height = MAX_LENGTH;
          context.drawImage(img, sx, sy, sLength, sLength, 0, 0, MAX_LENGTH, MAX_LENGTH);
        } else {
          context.canvas.width = beforeWidth;
          context.canvas.height = beforeHeight;
          context.drawImage(img, 0, 0);
        }
        setImage(context.canvas.toDataURL());
      },
      false,
    );
    img.src = image;
  }, []);

  const containerStyle = {
    width: '90%', 
    paddingTop:'2em', 
    margin:'0 auto'
  }

  const captionStyle = {
    color: STYLE.COLOR_TERTIARY,
    margin: '10px 0 5px',
    paddingLeft: '10px'
  }

  const cardStyle = {
    padding: '0.5em 1em',
    whiteSpace: 'pre-wrap'
  }

  const authStyle = {
    ...cardStyle,
    display: 'grid',
    gridTemplateRows: 'auto 3em 3em',
    gridTemplateColumns: '40px 1fr',
    gridTemplateAreas: '"desscription desscription" "googleLogo googleLink" "xLogo xLink"'
  }

  const profileStyle = {
    ...cardStyle,
    display: 'grid',
    gridTemplateRows: 'auto auto 1fr auto auto',
    gridTemplateColumns: `${isMobile() ? '100px' : '150px'} 1fr auto`,
    gridTemplateAreas: '"image id edit" "image name name" "image profile profile"  "image language language" "image editButton editButton"',
    columnGap: '10px',
  }

  const prImageStyle = {
    gridArea: 'image',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '10px'
  }

  const imageStyle = {
    maxWidth: isMobile() ? '100px' : '150px',
    maxHeight: isMobile() ? '100px' : '150px'
  }

  const prIdStyle = {
    gridArea: 'id'
  }

  const prEditStyle = {
    gridArea: 'edit'
  }

  const prNameStyle = {
    gridArea: 'name'
  }

  const prProfileStyle = {
    gridArea: 'profile',
    paddingTop: '10px'
  }

  const prLangStyle = {
    gridArea: 'language'
  }

  const prEditButtonStyle = {
    gridArea: 'editButton',
    textAlign: 'right',
    paddingTop: '10px'
  }

  const logoStyle = {
    display: 'flex',
    alignItems: 'center'
  }

  const linkStyle = {
    display: 'flex',
    alignItems: 'center'
  }

  const authDescStyle = {
    gridArea: 'desscription',
  }

  const googleLogoStyle = {
    ...logoStyle,
    gridArea: 'googleLogo'
  }

  const xLogoStyle = {
    ...logoStyle,
    gridArea: 'xLogo'
  }

  const googleLinkStyle = {
    ...linkStyle,
    gridArea: 'googleLink'
  }

  const xLinkStyle = {
    ...linkStyle,
    gridArea: 'xLink'
  }

  const iconStyle = {
    fontSize: '14px',
    position: "relative",
    top: "-2px",
    marginLeft: "3px"
  }

  const loaderContainerStyle = {
    textAlign: 'center'
  }

  const snackBarStyle = { 
    vertical: 'top', 
    horizontal:'center' 
  }
  
  return (
    <>
      <Header
        user={props.user} 
        loading={loading}
        setLoading={setLoading}
        forceReauth={props.forceReauth}
      />
      <div style={containerStyle}>
        {authError ? 
          <Alert severity="error">
            {authError.message} ({ authError.code })
          </Alert> : null}
        
        <h2 style={captionStyle}>{t.profile}</h2>
        <Paper elevation={3} style={profileStyle}>
          <Typography style={prIdStyle} gutterBottom variant="h5" component="div">
            {user.id}
          </Typography>
          <div style={prEditStyle}>
            {edit ? 
              null : 
              <Button sx={{minWidth:0, pr:0}} 
                startIcon={<Icon.Edit/>} 
                onClick={() => {
                  setEdit(true);
                  setNameError(false);
                  setLang(user.lang);
                  setImage(user.image);
                }}>{isMobile() ? null : t.edit}
              </Button>}
          </div>
          <div style={prNameStyle}>
            {edit ? 
              <TextField 
                label={t.name}
                error={nameError}
                inputRef={nameRef}
                defaultValue={user.name}
                inputProps={{ maxLength: 30}}
                onChange={() => setNameError(!nameRef.current.value)}
                helperText={nameError ? t.required : null}
                variant="filled" required fullWidth/>
              : user.name
            }
          </div>
          <div style={prProfileStyle}>
            {edit ? 
              <TextField
              label={t.profile}
              inputRef={profileRef}
              multiline
              rows={4}
              fullWidth
              defaultValue={user.profile}
              inputProps={{ maxLength: 150}}
              variant="filled"
            />
              : user.profile
            }
          </div>
          <div style={prLangStyle}>
            {edit ? 
              <FormControl variant="filled" sx={{ mt: 1 }}>
                <InputLabel id="lang-label">{t.language}</InputLabel>
                <Select
                  labelId="lang-label"
                  value={lang}
                  onChange={(event) => {setLang(event.target.value)}}
                >
                  {Object.keys(i18n).map((lang) => {
                    return <MenuItem key={lang} value={lang}>{i18n[lang].langName}</MenuItem>
                  })}
                </Select>
              </FormControl>
              : `${t.language}: ${t.langName}`
            }
          </div>
          <div style={prImageStyle}>
            {(edit && image) || (!edit && user.image) ? 
              <img alt={user.name} style={imageStyle} 
                src={edit ? image : props.user.image}/> 
                : <Icon.Image />}
            {edit ?
              <ImageLoader onLoad={resizeImage}/>
            : null}
          </div>
          {edit ?
            <div style={prEditButtonStyle}>
              <Button
                size="small"
                startIcon={<Icon.Ok/>} 
                disabled={nameError || loading}
                onClick={() => {
                  setLoading(true);
                  updateDoc(doc(db, "users", props.user.uid), {
                    name: nameRef.current.value.trim(),
                    profile: profileRef.current.value?.trim(),
                    lang: lang,
                    image: image ?? null
                  }).then(() => {
                    props.setUser((userOld) => {
                      return {...userOld, 
                        name: nameRef.current.value, 
                        profile: profileRef.current.value,
                        lang: lang,
                        image: image
                      };
                    });
                    setEdit(false);
                    setLoading(false);
                  }).catch((e) => {
                    setSnackBarMsg(e.message);
                    console.error("Error adding document: ", e);
                    setLoading(false);
                  });
                }
                }>{t.ok}
              </Button>
              <Button
                sx={{minWidth:0, ml:'10px'}}
                size="small"
                startIcon={<Icon.Cancel/>} 
                onClick={() => setEdit(false)}>{t.cancel}
              </Button>
            </div>
          : null}
        </Paper>

        <h2 style={captionStyle}>{t.authenticaton}</h2>
        <Paper elevation={3} style={authStyle}>
        <p style={authDescStyle}>{t.recommendMultiAuth}</p>
        <div style={googleLogoStyle}>
          <img width="18px" height="18px" alt="Google" src="logos/google.png"/>
        </div>
        <div style={googleLinkStyle}> 
        {linked.get(PROVIDER_ID_GOOGLE) ? t.linked : 
          <>{t.notLinked}
            <IconButton style={iconStyle} color="primary" 
              onClick={makeLinkHandle(new GoogleAuthProvider())}>
              <Icon.Link title={t.link}/>
            </IconButton>
          </>
        }
        {linked.get(PROVIDER_ID_GOOGLE) && moreThen2Provider ?
          <IconButton style={iconStyle} color="primary" 
            onClick={makeUnlinkHandle(PROVIDER_ID_GOOGLE)}>
            <Icon.Unlink title={t.unlink}/>
          </IconButton>
        : null}
        </div>
        <div style={xLogoStyle}>
          <img width="18px" alt="x" src="logos/x.svg"/>
        </div>
        <div style={xLinkStyle}>
        {linked.get(PROVIDER_ID_X) ? t.linked : 
          <>{t.notLinked}
            <IconButton style={iconStyle} color="primary" 
              onClick={makeLinkHandle(new TwitterAuthProvider())}>
              <Icon.Link title={t.link}/>
            </IconButton>
          </>
        }
        {linked.get(PROVIDER_ID_X) && moreThen2Provider ?
          <IconButton style={iconStyle} color="primary" 
            onClick={makeUnlinkHandle(PROVIDER_ID_X)}>
            <Icon.Unlink title={t.unlink}/>
          </IconButton> 
        : null}
        </div>
        </Paper>

        <h2 style={captionStyle}>{t.deactive}</h2>
        <Paper elevation={3} style={cardStyle}>
          <p>{t.deactiveCaption}</p>
          <Button onClick={() => setConfirmation(true)}>{t.deactivate}
          </Button>
        </Paper>
        <Dialog open={reAuth} onClose={()=> setReAuth(false)}>
          <DialogTitle>{t.reAuth}</DialogTitle>
          <DialogActions>
            {linked.get(PROVIDER_ID_GOOGLE) ? 
            <Button onClick={makeReLoginHandle(new GoogleAuthProvider())}>{t.loginGoogle}</Button>
            : null}
            {linked.get(PROVIDER_ID_X) ? 
            <Button onClick={makeReLoginHandle(new TwitterAuthProvider())}>{t.loginX}</Button>
            : null}
          </DialogActions>
        </Dialog>

        <Dialog
          open={confirmation}
          onClose={() => setConfirmation(false)}
        >
          <DialogContent>
            <DialogContentText>
            {t.deactiveConfirmation}
            </DialogContentText>
            <div style={loaderContainerStyle}></div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setLoading(true);
              deleteUser(props.user).then(() => {
                window.location.href = "/";
              }).catch((error) => {
                setConfirmation(false)
                if ('auth/requires-recent-login') 
                  setReAuth(true);
                else
                  setAuthError(error);
              });
            }}>
              {t.ok}
            </Button>
            <Button onClick={() => setConfirmation(false)} autoFocus>
              {t.cancel}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Snackbar 
        autoHideDuration={6000} 
        anchorOrigin={snackBarStyle} 
        open={snackBarMsg !== null} 
        message={snackBarMsg}
        onClose={() => setSnackBarMsg(null)}
      />
    </>
  );
};

function LoaderHandle(user) {
  return async ({request, params}) => {
    const docRef = doc(db, "users", user.uid);
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists())
        return docSnap.data();
      else
        throw new Response("Not Found", { status: 404 });
    } catch (error) {
      throw new Response(error.name + ' ' + error.message, { status: 403 });
    }
  };
}

Element.propTypes = {
  user: PropTypes.object,
  setUser: PropTypes.func,
  forceReauth: PropTypes.func,
}

const Profile = {
  Element,
  LoaderHandle,
};

export default Profile;
import React, { useEffect } from 'react';
import { useNavigate, useOutletContext } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import CardActionArea from '@mui/material/CardActionArea';
import * as STYLE from "./../common/Style";
import { LoadingIcon, isMobile } from "../common/Util";
import CardMedia from '@mui/material/CardMedia';
import Link from '@mui/material/Link';
import postscribe from 'postscribe';

export default function Trend(props) {

  const t = window.t;
  const navigate = useNavigate();
  const [, setMenu] = useOutletContext().menuState;
  const [trend, ] = useOutletContext().trendState;
  const [, setLoading] = useOutletContext().loadingState;
  const year = (new Date()).getFullYear();

  useEffect(() => {
    setMenu(0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    postscribe(
      '#addPlace',
      isMobile() ? `<script type="text/javascript">rakuten_design="slide";rakuten_affiliateId="0c37c7a8.b2a17ac2.0c37c7a9.69dbb093";rakuten_items="ctsmatch";rakuten_genreId="0";rakuten_size="300x160";rakuten_target="_blank";rakuten_theme="gray";rakuten_border="off";rakuten_auto_mode="on";rakuten_genre_title="off";rakuten_recommend="on";rakuten_ts="1713687585069";</script><script type="text/javascript" src="https://xml.affiliate.rakuten.co.jp/widget/js/rakuten_widget.js?20230106"></script>`
      : `<script type="text/javascript">rakuten_design="slide";rakuten_affiliateId="0c37c7a8.b2a17ac2.0c37c7a9.69dbb093";rakuten_items="ctsmatch";rakuten_genreId="0";rakuten_size="728x200";rakuten_target="_blank";rakuten_theme="gray";rakuten_border="off";rakuten_auto_mode="on";rakuten_genre_title="off";rakuten_recommend="on";rakuten_ts="1713687341313";</script><script type="text/javascript" src="https://xml.affiliate.rakuten.co.jp/widget/js/rakuten_widget.js?20230106"></script>`
    );
  }, []);

  const publishedStyle = {
    color: STYLE.COLOR_ON_SRFACE_VARIANT,
    fontSize: '90%',
    marginBottom: '8px'
  }

  const descStyle = {
    display: '-webkit-box',
    marginBottom: 0,
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden'
  }

  const pickupContainerStyle = {
    display: 'flex',
    flexDirection: 'row'
  }

  const loadingIconStyle = {
    top: '7em', 
    left: 'calc(50% - 20px)'
  }

  const rulesStyle = {
    textAlign: isMobile() ? 'left' : 'center', 
    fontSize: '80%', 
    padding: isMobile() ? '1em 100px 1em 1em' : '1em 0'
  }

  const rulesLinkStyle = {
    marginRight: isMobile() ? 0 : '2em',
    display: isMobile() ? 'block' : 'inline',
    cursor: 'pointer',
  }

  const footerInfoStyle = {
    margin: 0,
  }

  return (
    <>
      <Card sx={{ wordBreak: 'break-all'}}>
        {trend?.info?.description ? 
          <CardContent>
            <Typography>{trend.info.description}</Typography>
          </CardContent>
        : null}
        {trend?.pickup?.id ?
          <div style={pickupContainerStyle}>
          {trend.pickup.image ? 
            <CardMedia
            component="img"
            sx={{ maxWidth: isMobile() ? 90 : 145, maxHeight: 90, alignSelf: 'center'}}
            image={trend.pickup.image}
            alt={trend.pickup.image}
            />
          : null}
          <CardActionArea onClick={() => {
            navigate('/m/' + trend.pickup.id, { state: {back: true} });
            setLoading(true);
          }}>
            <CardContent>
              <Typography sx={{ fontSize: 18, fontWeight: 'bold'}}  >
                {t.pickup}
              </Typography>
              <Typography sx={{ fontSize: 18, fontWeight: 'bold'}} color="secondary.main" >
                {trend.pickup.title}
              </Typography>
              <Typography sx={{ wordBreak: 'break-all'}}>
                {trend.pickup.description}
              </Typography>
            </CardContent>
          </CardActionArea>
        </div>
      : null}
      </Card>
      <div id="addPlace" style={{
        width:'100%', 
        height: isMobile() ? '160px' : '200px', 
        textAlign: 'center', 
        overflow:'hidden'
      }}></div>
      {trend ? 
      <>
        <List sx={{ width: '100%', wordBreak: 'break-all'}}>
          {trend.rank.map((map, index) => {
            return <React.Fragment key={map.id}>
              <ListItem>
                <ListItemButton onClick={() => {
                    setLoading(true);
                    navigate('/m/' + map.id, { state: {back: true} });
                  }}>
                    <ListItemIcon sx={{width:30, mr:2}} style={{justifyContent: 'center'}}>
                      <Typography sx={{ fontSize: 30, fontWeight: 'bold' }} color="secondary.main">
                        {index + 1}
                      </Typography>
                    </ListItemIcon>
                    <ListItemText
                      primary={map.title ?? t.noTitle}
                      secondary={<>
                        <span style={publishedStyle}>{map.draft ? t.draft : t.dtFormater(map.published.toDate())}</span>
                        <span style={descStyle}>{map.description ?? ''}</span>
                      </>}
                      primaryTypographyProps={{sx:{ color: 'primary.main' }}}
                    />
                  </ListItemButton>
              </ListItem>
            </React.Fragment>;
          })}
        </List>
        <div style={rulesStyle}>
          <p style={footerInfoStyle}>
            <Link 
              style={rulesLinkStyle}
              onClick={() => {
                setLoading(true);
                navigate('/rule/tu');
              }}
            >{t.termsOfUse}</Link>
            <Link 
              style={rulesLinkStyle}
              onClick={() => {
                setLoading(true);
                navigate('/rule/pp');
              }}>{t.privacyPolicy}</Link>
            <Link 
              style={rulesLinkStyle}
              onClick={() => {
                setLoading(true);
                navigate('/rule/cp');
              }}>{t.contentsPolicy}</Link>
            <Link 
              style={rulesLinkStyle}
              onClick={() => {
                setLoading(true);
                navigate('/about');
              }}>{t.aboutContactUs}</Link>
          </p>
        (C) 2024{year > 2024 ? ` - ${year}` : ''} Toboggar
        </div>
      </>
      : 
      <LoadingIcon style={loadingIconStyle}/> }
    </>
  );
}

Trend.propTypes = {
};

import Header from "./Header";
import { ScrollRestoration } from "react-router-dom";
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import * as STYLE from "../common/Style";
import {setHeaderInfo} from "../common/Util";
import Link from '@mui/material/Link';

export default function AboutUs(props) {

  const t = window.t;
  const [loading, setLoading] = useState(false);
  setHeaderInfo(`${t.aboutContactUs} - Toboggar`, '', t.lang);

  useEffect(() => {
    setLoading(false);
    // eslint-disable-next-line 
  }, []);

  const containerStyle = {
    width: '90%', 
    margin:'0 auto'
  }
  
  const aboutStyle = {
    padding: '0.5em 2em',
    whiteSpace: 'pre-wrap',
    marginBottom: '0.5em'
  }

  const captionStyle = {
    color: STYLE.COLOR_TERTIARY,
    margin: '10px 0 5px',
    paddingLeft: '10px'
  }

  const linkStyle = {
    cursor: 'pointer',
  }

  return (
    <>
      <ScrollRestoration/>
      <Header user={props.user} loading={loading} setLoading={setLoading} />
      <div style={containerStyle}>
        <h2 style={captionStyle}>{t.aboutUs}</h2>
        <Paper elevation={3} style={aboutStyle}>
          <div>
            {t.managedBy}
            <ul>
              <li>
                X:&nbsp;
                <Link 
                  onClick={() => window.open('https://twitter.com/hiroatsu', '_blank')}
                  style={linkStyle}
                >
                  @hiroatsu
                </Link>
              </li>
            </ul>
          </div>
        </Paper>
      </div>
      <div style={containerStyle}>
        <h2 style={captionStyle}>{t.contactUs}</h2>
        <Paper elevation={3} style={aboutStyle}>
          <ul>
            <li>
              X:&nbsp;
              <Link 
                onClick={() => window.open('https://twitter.com/toboggar', '_blank')}
                style={linkStyle}
              >
                @toboggar
              </Link> 
            </li>
            <li>
              Instagram:&nbsp;
              <Link
                onClick={() => window.open('https://www.instagram.com/toboggar/', '_blank')}
                style={linkStyle}
              >
                @toboggar
              </Link>
            </li>
          </ul>
        </Paper>
      </div>
    </>
  );
}


AboutUs.propTypes = {
  user: PropTypes.object
}

import Header from "./Header";
import { useLoaderData, useNavigate, ScrollRestoration } from "react-router-dom";
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import * as STYLE from "../common/Style";
import { marked } from 'marked';
import Link from '@mui/material/Link';
import {setHeaderInfo} from "../common/Util";

function Element(props) {

  const t = window.t;
  const rule = useLoaderData();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  setHeaderInfo(`${t[rule.type]} - Toboggar`, '', t.lang);

  useEffect(() => {
    setLoading(false);
  // eslint-disable-next-line 
}, []);

  const containerStyle = {
    width: '90%', 
    margin:'0 auto'
  }
  
  const ruleStyle = {
    padding: '0.5em 2em',
    whiteSpace: 'pre-wrap',
    marginBottom: '0.5em'
  }

  const captionStyle = {
    color: STYLE.COLOR_TERTIARY,
    margin: '10px 0 5px',
    paddingLeft: '10px'
  }

  const remarksStyle = {
    paddingLeft: '10px'
  }

  return (
    <>
      <style>{`
        a {
          color: ${STYLE.COLOR_ON_BACKGROUND};
        }
        h3, h4 {
          margin: 1em 0 0;
        }
        p, ul {
          padding-left: 1em;
          margin: 0;
          overflow-wrap: break-word;
        }
       `}</style>
      <ScrollRestoration/>
      <Header user={props.user} loading={loading} setLoading={setLoading} />
      <div style={containerStyle}>
        <h2 style={captionStyle}>{t[rule.type]}</h2>
        {rule.lang === 'ja' ? null : <p style={remarksStyle}>{t.ruleTranslateRemark1}
          <Link href="." onClick={(e) => {navigate('?lang=ja'); e.preventDefault(); }}>{t.ruleTranslateRemark2}</Link>
        {t.ruleTranslateRemark3}</p>}
        <Paper elevation={3} style={ruleStyle}>
          <div dangerouslySetInnerHTML={{ __html:marked.parse(rule.text)}}></div>
        </Paper>
      </div>
    </>
  );
}

async function Loader ({request, params}) {
  const versionSufix = params.version ? '_' + params.version : '';
  let prefix;
  let lang = new URL(request.url).searchParams.get('lang');
  const t = window.t;
  if (!lang)
    lang = t.lang;
  const ret = {};
  switch (params.rule) {
    case 'pp':
      prefix = 'private_policy';
      ret.type = 'privacyPolicy';
      break;
    case 'tu':
      prefix = 'terms_of_use';
      ret.type = 'termsOfUse';
      break
    case 'cp':
      prefix = 'contents_policy';
      ret.type = 'contentsPolicy';
      break
    default:
    throw new Response("Not Found", { status: 404 });
  }
  const data = await fetch(`/rules/${prefix}${versionSufix}_${lang}.txt`);
  ret.text = await data.text();
  ret.lang = lang;

  if (!ret.text)
    throw new Response("Not Found", { status: 404 });

  return ret;
}

const Rule = {
  Element,
  Loader,
};

Element.propTypes = {
  user: PropTypes.object
}

export default Rule;
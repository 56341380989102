import { useNavigate } from "react-router-dom";
import { useState, useEffect, useCallback } from 'react';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { isMobile, LoadingIcon } from "../common/Util";
import { useInfiniteHits, Highlight, Snippet, useInstantSearch } from 'react-instantsearch';
import * as STYLE from "../common/Style";
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

export const BLANK_GEO_JSON = {
  type: "FeatureCollection",
  features: []
}

export default function InfiniteHits(props) {
  const { hits, showPrevious, showMore, isFirstPage, isLastPage } = useInfiniteHits();
  const navigate = useNavigate();
  const t = window.t;
  const [item, setItem] = useState(-1); // -1: no data
  const { status, results } = useInstantSearch();

  const buttonStyle = {
    display: 'block',
    margin: 'auto'
  }

  const hitsStyle = {
    overfllowX: 'hidden',
    overfllowY: 'scroll',
  }

  const selectItem = useCallback((index) => {
    setItem(index);
    if (index === -1) {
      props.updateGeoInfo(BLANK_GEO_JSON);
    } else if (hits && hits[index]) {
      
      const features = []
      hits[index]._geoloc.forEach(geo => 
        features.push({
          "type": "Feature",
          "properties": {},
          "geometry": {
              "type": "Point",
              "coordinates": [geo.lng, geo.lat]
          }
        })
      );
  
      const geoJson = {
        type: "FeatureCollection",
        features: features
      };

      props.updateGeoInfo(geoJson);
    }
  }, [hits, props]);

  const loadingIconStyle = {
    display: 'block', 
    margin: '2em auto 0'
  }

  useEffect(() => {
    selectItem(-1);
  // eslint-disable-next-line 
  }, [results.query]);

  useEffect(() => {
    if (item === -1 && status === 'idle' && hits.length > 0)
      selectItem(0);
  // eslint-disable-next-line 
  }, [status, hits.length, item]);

  return (
    <>
      <style>{`
        mark {
          color: ${STYLE.COLOR_ON_BACKGROUND};
          background: ${STYLE.COLOR_BACKGROUND};
          font-weight: bold;
        }
        a mark {
          color: ${STYLE.COLOR_PRIMARY};
        }
       `}</style>
      <div style={hitsStyle}>
        {isFirstPage ? null:
          <Button style={buttonStyle} onClick={showPrevious} variant="text">{t.previousPage}</Button>
        }
        {status === 'stalled' ? 
          <LoadingIcon style={loadingIconStyle}/> 
          : 
          <List sx={isMobile() ? {ml:2, mr:2} : {ml:8, mr:8}}>
            {hits.map((hit, index) => (
              <ListItem key={hit.objectID}>
                <ListItemButton 
                  selected={item === index}
                  onClick={() => selectItem(index)}
                  >
                  <ListItemText
                    primary={
                      <Link href="." onClick={(e) => {
                        navigate('/m/' + hit.objectID, { state: {back: true} }); 
                        e.stopPropagation();
                        e.preventDefault();
                        props.startLoading();
                      }}>
                        <Highlight attribute="title" hit={hit} />
                      </Link>}
                    secondary={<Snippet hit={hit} attribute="description" />}
                    primaryTypographyProps={{sx:{ color: 'primary.main' }}}
                  />
                </ListItemButton>
            </ListItem>
          ))}
          </List>
        }
        {isLastPage ? null : 
          <Button style={buttonStyle} onClick={showMore} variant="text">{t.morePage}</Button>
        }
      </div>
    </>
  );
}


InfiniteHits.propTypes = {
  updateGeoInfo: PropTypes.func,
  startLoading: PropTypes.func,
}

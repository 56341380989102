import ErrorPage from "./Error";
import {createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import UserMap from './umap/UserMap';
import User from './user/User';
import Main from './main/Main';
import Rule from './main/Rule';
import AboutUs from './main/AboutUs';
import Search from './search/Search';
import Profile from './main/Profile';
import Editor from './editor/Editor';
import NewMap from './editor/NewMap';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useRef, useEffect, useCallback } from 'react';
import mapboxgl from 'mapbox-gl';
import { useAsyncReference } from "./common/Util";
import PropTypes from 'prop-types';
import SignIn from "./main/SignIn";
import Like from "./main/Like";
import Trend from "./main/Trend";
import HowToUse from "./main/HowToUse";

function App(props) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const previousePath = useRef();
  const mapContainerStyle = {
    display: "none"
  };
  const [dummyCounter, forceUpdate] = useAsyncReference(0); // for firing resize event
  const forceResize = useCallback(() => forceUpdate(dummyCounter.current + 1), [dummyCounter, forceUpdate]);
  mapboxgl.accessToken = process.env.REACT_APP_MB_KEY;

  let userAgent = window.navigator.userAgent;
  const isBot = /bot|crawler|spider|crawling|InspectionTool/.exec(userAgent);
  const isAdBot = /Mediapartners-Google|AdSense/.exec(userAgent);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Main
        forceReauth={props.forceReauth}
        user={props.user}
        isBot={isBot !== null || isAdBot !== null}
        />,
      children: [
        { index: true, element: <Trend /> },
        {
          path: "signin",
          element: props.user ? <Navigate to="/" replace /> : <SignIn/>
        },
        {
          path: "like",
          element: props.user ? <Like.Element/> : <Navigate to="/" replace />,
          loader: props.user ? Like.LoaderHandle(props.user) : null,
        },
        {
          path: "howto",
          element: <HowToUse/>
        },
      ],
      errorElement: <ErrorPage />
    },
    {
      path: "/rule/:rule/:version?",
      element: <Rule.Element 
        user={props.user}
      />,
      loader: Rule.Loader,
      errorElement: <ErrorPage />
    },
    {
      path: "/about",
      element: <AboutUs 
        user={props.user}
      />,
      errorElement: <ErrorPage />
    },
    {
      path: "/search",
      element: <Search 
        user={props.user}
        map={map}
        mapContainer={mapContainer}
        dummyCounter={dummyCounter.current}
    />,
      errorElement: <ErrorPage />
    },
    {
      path: "/profile",
      element: props.user ? <Profile.Element
        user={props.user}
        setUser={props.setUser}
        forceReauth={props.forceReauth}
        />
        : <Navigate to="/" replace />,
      loader: props.user ? Profile.LoaderHandle(props.user) : null,
      errorElement: <ErrorPage />
    },
    {
      path: "m/:mapId",
      element: isBot && !isAdBot ? <UserMap.ElementForBot/> :
        <UserMap.Element 
          map={map}
          mapContainer={mapContainer}
          dummyCounter={dummyCounter.current}
          user={props.user}/>,
      loader: isBot || isAdBot ? UserMap.LoaderForBot : UserMap.LoaderHandle(props.user),
      errorElement: <ErrorPage />
    },
    {
      path: "u/:userId",
      element: 
        <User.Element 
          user={props.user}
        />,
      loader: isBot || isAdBot ? User.LoaderForBot : User.LoaderHandle(props.user),
      errorElement: <ErrorPage />
    },
    {
      path: "edit/:mapId",
      element: <Editor.Element 
        map={map}
        mapContainer={mapContainer} 
        user={props.user}
        dummyCounter={dummyCounter.current}
      />,
      loader: Editor.Loader,
      errorElement: <ErrorPage />
    },
    {
      path: "edit",
      element: 
        <NewMap 
          map={map}
          mapContainer={mapContainer}
          user={props.user}
          dummyCounter={dummyCounter.current}
        />
    },
    {
      path: "*",
      element: <Navigate to="/" replace />,
    }
  ]);

  router.subscribe((state) => {
    if (previousePath.current !== state.location.pathname) {
      previousePath.current = state.location.pathname;
      if (map.current)
        map.current.fire('closeAllPopups');
      if (mapContainer.current?.style)
        mapContainer.current.style.display = 'none';
    }
  });
  
  useEffect(() => {
    window.addEventListener('resize', forceResize);

    return () => {
      window.removeEventListener('resize', forceResize);
    }
  }, [forceResize]);

  return (
    <>
      <div ref={mapContainer} style={mapContainerStyle}/>
      <RouterProvider router={router} />
    </>
  );
}

App.propTypes = {
  user: PropTypes.object,
  setUser: PropTypes.func,
  forceReauth: PropTypes.func,
};

export default App;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Auth from './Auth';
import i18n, {langs} from './common/I18n'
import { ErrorBoundary } from "react-error-boundary";
import Error from './Error';

const root = ReactDOM.createRoot(document.getElementById('root'));
let lang = "en";
const browserLangs = window.navigator.languages;
for(const bl of browserLangs){
  const l = bl.slice(0,2);
  if (langs.includes(l)) {
    lang = l;
    break;
  }
}
window.t = i18n[lang];
window.startAt = Date.now();

if(window.matchMedia('(display-mode: standalone)').matches){
  let prevState;

  const onDisplayStateChange = () => {
    const TIMEOUT_DURATION = 3600000;
    let nextState;

    if (document.visibilityState === 'hidden')
      nextState = 'hidden';
    else
      nextState = 'active';

    if (nextState !== prevState) {
      if (prevState === 'hidden' && !window.location.pathname.startsWith('/edit') &&
        (Date.now() - window.startAt) > TIMEOUT_DURATION)
        window.location.reload();
      prevState = nextState;
    }
  };

  ['pageshow', 'focus', 'blur', 'visibilitychange', 'resume'].forEach((type) => 
    window.addEventListener(type, onDisplayStateChange, {capture: true})
  );
}

root.render(
  <React.StrictMode>
    <Auth/>
    <ErrorBoundary fallback={Error}>
    </ErrorBoundary>
  </React.StrictMode>
);

navigator.serviceWorker.register('/service-worker.js', {scope: '/'});
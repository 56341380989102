import { getDoc, doc, updateDoc, serverTimestamp, Timestamp, writeBatch } from "firebase/firestore"; 
import { db, clearCache, isMobile, setHeaderInfo, MAP_ID, SwitchStyle, moveToTop } from "./../common/Util";
import { getStorage, ref, getBlob } from "firebase/storage";
import { useRef, useEffect, useState, useCallback } from 'react';
import { Icon } from "../common/Icon";
import { useLoaderData, useNavigate, useLocation } from "react-router-dom";
import { resizeMapContiner, setMap, AimingImage, MapSearch, makeLineGeojson } from "./MapEditUtil";
import HeaderEditor from "./HeaderEditor";
import ItemEditor from "./ItemEditor";
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';
import Snackbar from '@mui/material/Snackbar';
import LinearProgress from '@mui/material/LinearProgress';
import AppBar from '@mui/material/AppBar';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Fab from '@mui/material/Fab';
import * as STYLE from "./../common/Style";

function Element(props) {

  const t = window.t;
  const navigate = useNavigate();
  const location = useLocation();
  const mapPlaceHolderRef = useRef(null);
  const [loading, setLoading] = useState(!props.map.current?.isStyleLoaded());
  const [snackBarMsg, setSnackBarMsg] = useState(null);
  const [userMap, setUserMapState] = useState(useLoaderData().userMap);
  const mapId = useLoaderData().id;
  const [confirmation, setConfirmation] = useState(null);
  //-1: no edit, 0:header, >1: item, Number.MAX_SAFE_INTEGER: new item
  const [editItem, setEditItem] = useState(-1);
  const [imageCache, setImageCache] = useState(new Map());
  const [mode, setMode] = useState('dark');

  setHeaderInfo(`${t.editMap} - Toboggar`, '', t.lang);
  
  const changeEditItem = useCallback((id, afterUpdate) => {
    if (!afterUpdate && editItem === Number.MAX_SAFE_INTEGER) {
      const index = userMap.features.findIndex((e) => e.id === Number.MAX_SAFE_INTEGER);
      const features = userMap.features.toSpliced(index, 1);
      setUserMapState({ ...userMap, features : features });
    } else if (editItem > 0)
      props.map.current.setFeatureState(
        { source: MAP_ID.data, id: editItem },
        { edit: false }
      );
    setEditItem(id);
  }, [props.map, editItem, userMap]);

  const updateUserMap = useCallback((updateProps, callback) => {
    setLoading(true);
    const updateMap = { ...userMap, ...updateProps, lang: t.lang };
    let updateTime = null;
    if (updateMap.draft || (userMap.draft && !updateMap.draft)) {
      updateMap.published = serverTimestamp();
      updateTime = Timestamp.fromDate(new Date());
    } else {
      updateTime = userMap.published;
      delete updateMap.published;
    }
    updateMap.update = serverTimestamp();

    updateDoc(doc(db, "maps", mapId), updateMap).then(async () => {
      clearCache();
      setLoading(false);
      updateMap.published = updateTime;
      setUserMapState(updateMap);
      if (callback)
        callback();
    }).catch((e) => {
      setSnackBarMsg(e.message);
      console.error("Error adding document: ", e);
      setLoading(false);
    });
  }, [mapId, t.lang, userMap]);

  const setUserMap = useCallback((userMap) => {
    setUserMapState(userMap);
  }, []);

  useEffect(() => {
    const allBounds = new mapboxgl.LngLatBounds();
    userMap.features.forEach(item => allBounds.extend(item.geometry.coordinates));
    setMap(props, mapPlaceHolderRef.current, allBounds.getCenter(), false, 
      (mode) => loadData(allBounds, mode, true));
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => resizeMapContiner(
    props.mapContainer.current, 
    mapPlaceHolderRef.current.getBoundingClientRect(),
    props.map.current) 
  ,[props.dummyCounter, props.mapContainer, props.map]);
  
  useEffect(() => {
    userMap.features.forEach((item, index) => {
      if (item.properties.image && !imageCache.has(item.properties.image)) {
        const storage = getStorage();
        getBlob(ref(storage, `user/${userMap.uid}/maps/${mapId}/${item.properties.image}`), 1024*1024).then((blob) => {
          imageCache.set(item.properties.image, URL.createObjectURL(blob));
          setImageCache(new Map(imageCache));
        }).catch((error) => {
          console.error('image not found: ' + item.properties.image);
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMap.features]);

  const outerStyle = {
    display: 'grid',
    gridTemplateColumns: isMobile() ? '1fr' : '400px 1fr',
    gridTemplateRows: isMobile() ? '1fr 40vh' : '1fr',
    width: '100vw',
    height: '100%',
  }

  const editorStyle = {
    wordBreak: 'break-all',
    overflowWrap: 'break-word',
    overflowY: 'scroll',
    overflowX: 'hidden',
  }

  const mapDummyStyle = {
    position: 'relative',
    pointerEvents: 'none',
  }

  const snackBarStyle = { 
    vertical: 'top', 
    horizontal:'center' 
  }

  const fabOuter = {
    position: 'fixed',
    bottom: isMobile() ? 'calc(40vh + 15px)' : '15px',
    right: isMobile() ? '25px' : 'calc(100% - 400px + 25px)',
  }

  const publishStyle = {
    opacity: '0.8'
  }

  const iconStyle = {
    height: '18px',
    width: '18px',
    strokeWidth: 2
  }

  const publishIconStyle = {
    ...iconStyle,
    marginRight: '8px', 
  }

  const mapInstractionStyle = {
    position: 'relative',
    textAlign: 'center',
    color: STYLE.COLOR_THEME[mode].COLOR_ERROR,
    top: '50%',
    marginTop: '-30px',
    opacity: '0.5',
    pointerEvents: 'none'
  }

  const logoStyle = {
    width: '110px',
  }

  const switchStyleStyle = {
    position: 'absolute',
    top: '35px',
    left: '5px'
  }

  const dummyProgressBar = {
    height: '4px'
  }

  const mapSearchStyle = {    
    position: 'absolute', 
    top: '5px',
    left: '5px',
    pointerEvents: 'auto',
    width: '300px',
  }

  return (
    <div style={outerStyle}>
      <div style={editorStyle}>
        <AppBar position="sticky" sx={{p:1, flexDirection: 'row'}}>
          <Breadcrumbs sx={{ flexGrow: 1, m: 'auto' }} aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              onClick={(e) => {
                moveToTop(navigate);
                e.preventDefault();
              }}
              href="."
            >
              <img style={logoStyle} alt="Toboggar" src="/logo.svg" />
            </Link>
            <Typography
              sx={{ display: 'flex', alignItems: 'center' }}
              color="text.primary"
            >
              {t.editMap}
            </Typography>
          </Breadcrumbs>
          <div>
            <IconButton
              onClick={() => {
                setLoading(true);
                navigate(location.state?.back ? -1 : '/');
              }}
              aria-label={t.back}
              size="small"
              color="primary"
            >
              <Icon.Back style={iconStyle} title={t.back} />
            </IconButton>
            <IconButton
              onClick={() => {
                setLoading(true);
                navigate('/m/' + mapId, {state: {back: true}});
              }}
              aria-label={t.toMap}
              size="small"
              color="primary"
            >
              <Icon.Map style={iconStyle} title={t.toMap} />
            </IconButton>
            <IconButton 
              onClick={() => 
                setConfirmation({msg:t.confirmDelete, callback: async () => {
                  setLoading(true);
                  const mapRef = doc(db, `maps`, mapId);
                  try {
                    const deletedInf = {deleted: true, update: serverTimestamp()};
                    if (userMap.draft)
                      deletedInf.published = serverTimestamp();
                    const batch = writeBatch(db);
                    batch.update(mapRef, deletedInf);
                    batch.delete(mapRef);
                    await batch.commit();
                    clearCache();
                    navigate('/u/' + userMap.auther);
                  } catch (error) {
                    setLoading(false);
                    console.error("Error create map: ", error);
                    setSnackBarMsg(error.name + ' ' + error.message);
                  }
                }})
              }
              aria-label={t.delete}
              color="primary"
              size="small"
              >
              <Icon.Delete style={iconStyle} title={t.delete} />
            </IconButton>
          </div>
        </AppBar>
        {loading ? <LinearProgress /> : <div style={dummyProgressBar}></div>}
        <HeaderEditor 
          userMap = {userMap}
          loading = {loading}
          updateUserMap = {updateUserMap}
          editItem = {editItem}
          changeEditItem = {changeEditItem}
        />
        <ItemEditor
          userMap = {userMap}
          loading = {loading}
          updateUserMap = {updateUserMap}
          editItem = {editItem}
          changeEditItem = {changeEditItem}
          mapId = {mapId}
          map = {props.map}
          setSnackBarMsg = {setSnackBarMsg}
          setUserMap = {setUserMap}
        />
        <div style={fabOuter}>
          {userMap.draft && !loading && editItem === -1 ? 
            <Fab 
              size="small" 
              color="secondary" 
              variant="extended"
              style={publishStyle} 
              onClick={() => setConfirmation({
                msg:t.confirmPublish, 
                callback: async () => {
                  updateUserMap({draft : false})
                }
              })}
            >
              <Icon.Publish style={publishIconStyle} />
              {t.publish}
            </Fab> 
          : null}
        </div>
        <Dialog
          open={confirmation !== null}
          onClose={() => setConfirmation(null)}
        >
          <DialogContent>
            <DialogContentText>
            {confirmation?.msg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              confirmation.callback();
              setConfirmation(null)
            }}>
              {t.ok}
            </Button>
            <Button onClick={() => setConfirmation(null)} autoFocus>
              {t.cancel}
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar 
          autoHideDuration={6000} 
          anchorOrigin={snackBarStyle} 
          open={snackBarMsg !== null} 
          message={snackBarMsg}
          onClose={() => setSnackBarMsg(null)}
        />
      </div>
      <div ref={mapPlaceHolderRef} style={mapDummyStyle}>
        {editItem > 0 ? 
          <>
            <AimingImage mode={mode}/>
            <div style={mapInstractionStyle}>{t.mapInstractionEdit}</div>
          </>
        : null}
        <div style={mapSearchStyle}>
          <MapSearch map={props.map.current}/>
        </div>
        <SwitchStyle 
          loading={loading} 
          map={props.map.current}
          style={switchStyleStyle}
          size='small'
          iconSize='16px'
          onLoad={(mode) => {
            const allBounds = new mapboxgl.LngLatBounds();
            userMap.features.forEach(item => allBounds.extend(item.geometry.coordinates));
            loadData(allBounds, mode);
          }}/>
      </div>
    </div>
  );

  function loadData(bounds, mode, move) {
    const map = props.map.current;
    setMode(mode);
    map.loadImage(
      '../pin.png',
      (error, image) => {
        if (error) throw error;
        map.addImage(MAP_ID.image, image, { sdf: true });
        map.addSource(MAP_ID.data, {
          'type': 'geojson',
          'data': userMap
        });
        map.addLayer({
          'id': MAP_ID.layer,
          'type': 'symbol',
          'source': MAP_ID.data,
          'layout': {
            'icon-image': 'pin-marker',
            'text-field': ["concat", ['id'], '. ', ['coalesce', ['get', 'name'], t.noTitle]],
            'text-offset': [0, 0.5],
            'text-anchor': 'top',
            'text-size': 20,
            'text-allow-overlap': true,
            'icon-allow-overlap': true,
            "icon-anchor": 'bottom',
          },
          paint: {
            "icon-color": [
              'case',
              ['boolean', ['feature-state', 'edit'], false],
              STYLE.COLOR_THEME[mode].COLOR_ON_SURFACE,
              STYLE.COLOR_THEME[mode].COLOR_PRIMARY
            ],
            "text-color": STYLE.COLOR_THEME[mode].COLOR_PRIMARY,
            "icon-opacity": [
              'case',
              ['boolean', ['feature-state', 'edit'], false],
              0.5,
              1
            ],
            "text-opacity": [
              'case',
              ['boolean', ['feature-state', 'edit'], false],
              0,
              1
            ],
            "text-halo-blur": 3,
            "text-halo-color": STYLE.COLOR_THEME[mode].COLOR_PRIMARY_CONTAINER,
            "text-halo-width": 2
          }
        });
        map.addSource(MAP_ID.lineData, {
          type: 'geojson',
          data: makeLineGeojson(userMap),
        });
        map.addLayer({
          'id': MAP_ID.lineLayer,
          'type': 'line',
          'source': 'line',
          'layout': {
            'line-cap': 'round',
            'line-join': 'round'
          },
          paint: {
            'line-width': 2,
            'line-blur': 2,
            'line-gap-width': 3,
            'line-color': STYLE.COLOR_THEME[mode].COLOR_SECONDARY
          }
        }, MAP_ID.layer);
        map.addLayer({
          'id': MAP_ID.lineTitle,
          'type': 'symbol',
          'source': 'line',
          'layout': {
            'text-field': ['get', 'title'],
            "symbol-placement": "line-center",
            'text-size': 20,
          },
          paint: {
            'text-color': STYLE.COLOR_THEME[mode].COLOR_SECONDARY,
            "text-halo-blur": 5,
            "text-halo-color": STYLE.COLOR_THEME[mode].COLOR_SECONDARY_CONTAINER,
            "text-halo-width": 3,
          }
        }, MAP_ID.layer);
    
        if (move) {
          const canvas = document.querySelector('.mapboxgl-canvas');
          if (canvas && canvas.clientWidth > 150 && canvas.clientHeight > 150)
              map.fitBounds(bounds,{padding: 50, linear: true});
        }
        setLoading(false);
      }
    );
  }
};


async function Loader ({request, params}) {

  const docRef = doc(db, "maps", params.mapId);

  try {
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    delete data.like;
    return {userMap: data, id: params.mapId};
  } catch (error) {
    if (error.code === 'permission-denied')
      throw new Response("Not Found Map", { status: 404 });
    throw new Response(error.name + ' ' + error.message, { status: 403 });
  }

}

const Editor = {
  Element,
  Loader,
};

Element.propTypes = {
  map: PropTypes.object,
  mapContainer: PropTypes.object,
  dummyCounter: PropTypes.number
}

export default Editor;
import { ScrollRestoration, useNavigate, Outlet } from "react-router-dom";
import { useEffect, useState, useCallback } from 'react';
import { setHeaderInfo, isMobile, db } from "./../common/Util";
import { Icon } from "../common/Icon";
import { doc, onSnapshot, Timestamp } from "firebase/firestore";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Fab from '@mui/material/Fab';
import Header from "./Header";
import PropTypes from 'prop-types';

export default function Main(props) {

  const t = window.t;
  setHeaderInfo(`Toboggar - Share your trip`, t.siteDescription, t.lang);

  const [menu, setMenu] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isFixTab, setIsFixTab] = useState(false);
  const [loading, setLoading] = useState(false);
  const [trend, setTrend] = useState(null);

  const navigate = useNavigate();

  const toggleScrolled = useCallback(() => {
    window.scrollY > 200
      ? setIsScrolled(true)
      : setIsScrolled(false);
    window.scrollY > 64
      ? setIsFixTab(true)
      : setIsFixTab(false);
  },[]);

  useEffect(() => {
    window.addEventListener('scroll', toggleScrolled);
    return () => window.removeEventListener('scroll', toggleScrolled);
  }, [toggleScrolled]);

  useEffect(() => {
    if (props.isBot) {
      async function fetchFromProxy() {
        const res = await fetch(`${process.env.REACT_APP_FB_FC_DOMAIN}/botProxy/trend/${t.lang}`);
        if (!res.ok)
          throw new Response('Error', { status: res.status });
        const json = await res.json();
        json.rank.forEach(item => {
          item.published = new Timestamp(item.published._seconds, item.published._nanoseconds);
        });
        setTrend(json);
      }
      fetchFromProxy();
    } else {
      const unsubscribe = onSnapshot(doc(db, "trend", t.lang), 
        (doc) => setTrend(doc.data()));
      return () => unsubscribe();
    }
  }, [t.lang, props.isBot])

  function moveMain() {
    if (menu !== 0)
      navigate('/');
  }

  function moveSignIn() {
    if (menu !== 1)
      navigate('/signin');
  }

  function moveLike() {
    if (menu !== 1) {
      setLoading(true);
      navigate('/like');
    }
  }

  function moveHowTo() {
    if (menu !== 2)
      navigate('/howTo');
  }

  const tabStyle = {
    position: isFixTab ? 'fixed' : null,
    top: 0, 
    left: 0, 
    right: 0,
    zIndex: 10
  }

  const tabItemStyle = {
    minHeight: '50px',
    height: '50px',
  }

  const contentStyle = {
    paddingTop: !isMobile() && isFixTab ? '64px' : '0',
    paddingBottom: isMobile() ? '56px' : '0'
  }

  const fabOuter = {
    display: 'grid',
    position: 'fixed',
    bottom: isMobile() ? '81px' : '25px',
    right: '25px',
    gridTemplateColumns: '1fr',
    rowGap: '1em',
    justifyItems: 'end',
  }
  
  const upStyle = {
    opacity: '0.5',
    display: isScrolled ? 'block' : 'none' 
  }

  const upIconStyle = {
    strokeWidth: 2,
    width: '20px',
    height: '20px',
    marginTop: '8px'
  }

  const addStyle = {
    opacity: '0.8',
    fontSize: '200%'
  }

  return (
    <>
      <Header
        user={props.user} 
        loading={loading}
        setLoading={setLoading}
        forceReauth={props.forceReauth}
      />
      <ScrollRestoration/>
      {isMobile() ? null :
        <Paper sx={tabStyle} elevation={3}>
          <Tabs value={menu} onChange={(event, newValue) => {
                setMenu(newValue);
              }} aria-label="menu" variant="fullWidth"
              sx={{bgcolor:'background.paper'}}>
            <Tab icon={<Icon.Trend />} label={t.trend} iconPosition="start" style={tabItemStyle}
              onClick={moveMain}
            />
            {props.user ?
              <Tab icon={<Icon.Heart />} label={t.like} iconPosition="start" style={tabItemStyle}
                onClick={moveLike}
              />
              :
              <Tab icon={<Icon.Login />} label={t.login} iconPosition="start" style={tabItemStyle} 
                onClick={moveSignIn}
              />
            }
            <Tab icon={<Icon.Info />} label={t.howToUse} iconPosition="start" style={tabItemStyle}
                onClick={moveHowTo}
            />
          </Tabs>
        </Paper>
      }
      <div style={contentStyle}>
        <Outlet   context={{
          menuState: [menu, setMenu],
          trendState: [trend, setTrend],
          loadingState: [loading, setLoading],
          user: props.user
        }}/>
      </div>
      {isMobile() ? 
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex:2 }} elevation={3}>
          <BottomNavigation
            showLabels
            value={menu}
            onChange={(event, newValue) => {
              setMenu(newValue);
            }} >
            <BottomNavigationAction label={t.trend} icon={<Icon.Trend />} onClick={moveMain}/>
            {props.user ?
              <BottomNavigationAction label={t.like} icon={<Icon.Heart />} onClick={moveLike}/>
              :
              <BottomNavigationAction label={t.login} icon={<Icon.Login />} onClick={moveSignIn}/>
            }
            <BottomNavigationAction label={t.howToUse} icon={<Icon.Info />} onClick={moveHowTo}/>
          </BottomNavigation>
        </Paper>
      : null
      }
      <div style={fabOuter}>
        <Fab size="small" color="primary" style={upStyle} 
          onClick={() => window.scrollTo({top: 0, behavior: "smooth" })}>
          <Icon.Up title={t.up} style={upIconStyle}/>
        </Fab>
        {props.user ? <Fab size="large" color="secondary" style={addStyle} 
          onClick={() => navigate('/edit', { state: {back: true} })}>
          <Icon.Plus title={t.add} />
        </Fab> : null}
      </div>
    </>
  );
}

Main.propTypes = {
  user: PropTypes.object,
  forceReauth: PropTypes.func,
  isBot: PropTypes.bool
}


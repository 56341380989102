import Header from "../main/Header";
import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import * as STYLE from "../common/Style";
import { setHeaderInfo, searchClient, isMobile, MAP_ID, changeMapStyle, newMap, mapReset } from "../common/Util";
import { InstantSearch, Stats } from 'react-instantsearch';
import mapboxgl from 'mapbox-gl';
import SearchBox from "./SearchBox";
import InfiniteHits, {BLANK_GEO_JSON} from "./InfiniteHits";
import postscribe from 'postscribe';

export default function Search(props) {

  const t = window.t;
  const [loading, setLoading] = useState(false);
  const [geoInfo, setGeoInfo] = useState();
  const [mapLoaded, setMapLoaded] = useState(false);

  setHeaderInfo(`${t.search} - Toboggar`, '', t.lang);

  const updateGeoInfo = useCallback((newGeoInfo) => setGeoInfo(newGeoInfo), []);
  const startLoading = useCallback(() => setLoading(true), []);

  const setMarkers = useCallback(() => {
    if (geoInfo) {
      props.map.current.getSource(MAP_ID.data)?.setData(geoInfo);
      if (geoInfo.features.length > 0) {
        const allBounds = new mapboxgl.LngLatBounds();
        geoInfo.features.forEach(item => allBounds.extend(item.geometry.coordinates));
        props.map.current.fitBounds(allBounds,{padding: 10, linear: true});
      }
    }
  }, [geoInfo, props.map]);

  useEffect(() => {
    if (props.map.current?.isStyleLoaded()) {
      setMarkers();
    }
  // eslint-disable-next-line 
  }, [geoInfo, props.map]);

  useEffect(() => {
    if (mapLoaded) {
      setMarkers();
    }
  // eslint-disable-next-line 
  }, [mapLoaded]);

  const resizeMap = useCallback((contanerStyle) => {
    contanerStyle.height = '200px';
    if (isMobile()) {
      contanerStyle.width = '100%';
      contanerStyle.top = 'unset';
      contanerStyle.bottom = '0';
      contanerStyle.left = '0';
      contanerStyle.right = 'unset';
    } else {
      contanerStyle.width = '300px';
      contanerStyle.top = '440px';
      contanerStyle.bottom = 'unset';
      contanerStyle.left = 'unset';
      contanerStyle.right = '30px';
    }
    if (props.map.current)
      props.map.current.resize();
  }, [props.map]);

  function loadLayer() {
    const map = props.map.current;
    map.loadImage(
      '../circle.png',
      (error, image) => {
        if (error) throw error;
        map.addImage(MAP_ID.image, image, { sdf: true });
        map.addSource(MAP_ID.data, {
          'type': 'geojson',
          'data': BLANK_GEO_JSON
        });
        map.addLayer({
          'id': MAP_ID.layer,
          'type': 'symbol',
          'source': MAP_ID.data,
          'layout': {
            'icon-image': 'pin-marker',
            'icon-allow-overlap': true,
            "icon-anchor": 'center',
          },
          paint: {
            "icon-color": STYLE.COLOR_PRIMARY,
            "text-color": STYLE.COLOR_PRIMARY,
            "icon-opacity": 0.5,
          }
        });
        setMapLoaded(true);
        setLoading(false);
      }
    );
  }

  useEffect(() => {

    const contanerStyle = props.mapContainer.current.style;
    let map = props.map.current;

    if (contanerStyle.display !== 'block') {
      contanerStyle.display = 'block';
      contanerStyle.position = 'fixed';
      resizeMap(contanerStyle);
  
      if (map) {
        map.resize();
        mapReset(map);
        map.jumpTo({center: t.defaultPoint, zoom: 3});
        changeMapStyle(map, 'simple', loadLayer, true);
      } else {
        newMap(props, 'simple', null, t.defaultPoint, 3);
        props.map.current.on('load', () => changeMapStyle(props.map.current, 'simple', loadLayer, true));
      }
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    resizeMap(props.mapContainer.current.style);
  }, [props.dummyCounter, props.mapContainer, resizeMap]);

  useEffect(() => {
    postscribe(
      '#addPlace',
      isMobile() ? `<script type="text/javascript">rakuten_design="slide";rakuten_affiliateId="0c37c7a8.b2a17ac2.0c37c7a9.69dbb093";rakuten_items="ranking";rakuten_genreId="0";rakuten_size="300x160";rakuten_target="_blank";rakuten_theme="gray";rakuten_border="off";rakuten_auto_mode="on";rakuten_genre_title="off";rakuten_recommend="on";rakuten_ts="1713684747451";</script><script type="text/javascript" src="https://xml.affiliate.rakuten.co.jp/widget/js/rakuten_widget.js?20230106"></script>`
      : `<script type="text/javascript">rakuten_design="slide";rakuten_affiliateId="0c37c7a8.b2a17ac2.0c37c7a9.69dbb093";rakuten_items="ranking";rakuten_genreId="0";rakuten_size="300x250";rakuten_target="_blank";rakuten_theme="gray";rakuten_border="off";rakuten_auto_mode="on";rakuten_genre_title="off";rakuten_recommend="on";rakuten_ts="1713684290787";</script><script type="text/javascript" src="https://xml.affiliate.rakuten.co.jp/widget/js/rakuten_widget.js?20230106"></script>`
    );
    if (!isMobile())
      postscribe(
        '#headerAddPlace',
        `<script type="text/javascript">rakuten_design="slide";rakuten_affiliateId="0c37c7a8.b2a17ac2.0c37c7a9.69dbb093";rakuten_items="ctsmatch";rakuten_genreId="0";rakuten_size="728x90";rakuten_target="_blank";rakuten_theme="gray";rakuten_border="off";rakuten_auto_mode="on";rakuten_genre_title="off";rakuten_recommend="on";rakuten_ts="1713686876488";</script><script type="text/javascript" src="https://xml.affiliate.rakuten.co.jp/widget/js/rakuten_widget.js?20230106"></script>`
      );
  }, []);

  const hitsStyle = {
  }

  const mapWrapperStyle = {
    gridArea: 'map'
  }

  const conditionalQuery = {
    search(requests) {
      if (
        requests.every(({params}) => !params.query)
      ) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            processingTimeMS: 0,
          })),
        });
      }
      return searchClient.search(requests);
    },
  };

  const outerContainerStyle = {
    display: 'grid',
    gridTemplateColumns: isMobile() ? '1fr' : '1fr 330px',
    gridTemplateRows: isMobile() ? 'auto 1fr 200px' : 'auto 1fr',
    gridTemplateAreas: isMobile() ? '"header" "search" "map"' : '"header header" "search map"',
    height: '100%',
    width: '100%',
  }

  const searchStyle = {
    gridArea: 'search', 
    overflowY: isMobile() ? 'scroll' : 'visible'
  }

  return (
    <div style={outerContainerStyle}>
      <style>{`
        .stats {
          font-size:12px;
          color: ${STYLE.COLOR_ON_BACKGROUND};
          margin: ${isMobile() ? '0.5em 2em 1em' : '0.5em 5em 1em'};
          text-align: right;
        }
       `}</style>
      <div style={{gridArea: 'header'}}>
        <Header user={props.user} loading={loading} setLoading={setLoading} />
        {isMobile() ? null : <div id="headerAddPlace" style={{
        width: '100%', 
        height: '90px', 
        overflow: 'hidden',
        textAlign: 'center',
        }}></div>}
      </div>
      <div style={searchStyle}>
        {isMobile() ? <div id="addPlace" style={{
            overflow: 'hidden', 
            width: '100%', 
            height:'160px', 
            textAlign: 'center',
          }}></div> : null }
        <InstantSearch searchClient={conditionalQuery} indexName="toboggar"
          numberLocale={t.lang}
          future={{preserveSharedStateOnUnmount: true}}
        >
          <SearchBox />
          <Stats classNames={{root: 'stats'}}
            translations={{
              rootElementText({ nbHits, processingTimeMS, nbSortedHits, areHitsSorted }) {
                return processingTimeMS === 0 ? null : t.getSearchStat(nbHits.toLocaleString(), processingTimeMS.toLocaleString());
              }
            }}
          />
          <div style={hitsStyle}>
            <InfiniteHits updateGeoInfo={updateGeoInfo} startLoading={startLoading} />
          </div>
        </InstantSearch>
      </div>
      <div style={mapWrapperStyle}>
        {isMobile() ? null : <div id="addPlace" style={{
            overflow: 'hidden', 
            width: '300px', 
            height: '250px', 
            textAlign: 'center',
            marginTop: '20px'
          }}>
        </div>}
      </div>
    </div>
  );
}

Search.propTypes = {
  user: PropTypes.object,
  map: PropTypes.object,
  mapContainer: PropTypes.object,
  dummyCounter: PropTypes.number
}


import React, { useRef, useState } from 'react';
import { isMobile } from "./../common/Util";
import { Icon } from "./../common/Icon";
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Snackbar from '@mui/material/Snackbar';

export default function Share(props) {
  const t = window.t;
  const [shareMapStyle, setShareMapStyle] = useState('simple');
  const shareURL = useRef();
  const shareAuto = useRef();
  const [shareSnack, setShareSnack] = useState(false);

  const dialogIconStyle = {
    fontSize: "120%"
  }

  const mapStyleButtonStyle = {
    marginLeft: '0.5em',
    fontSize: isMobile() ? '80%' : 'inherit',
    lineHeight: isMobile() ? '1.3em' : 'inherit',
  }

  function setShareOption(style) {
    let url = new URL(window.location.pathname, window.location.href);
    let params = new URLSearchParams(url.search);
    if (shareAuto.current.checked)
      params.append('auto', 'true');
    if (style)
      params.append('style', style);
    else
      params.append('style', shareMapStyle);
    shareURL.current.value = url + (params.size > 0 ? '?' + params.toString() : '');
  } 

  return (
    <>
      <Dialog open={props.shareDlg} onClose={() => props.setShareDlg(false)}>
        <DialogTitle>{t.shareLink}</DialogTitle>
        <DialogContent>
          <TextField
            disabled
            fullWidth
            sx={{width: 280, mt:1, mb:2}}
            label={t.urlLink}
            defaultValue={new URL(window.location.pathname, window.location.href)}
            inputRef = {shareURL}
          />
          <ToggleButtonGroup
            value={shareMapStyle}
            exclusive
            size="small"
            sx={{mb:1}}
            onChange={(event, newStyle) => {
              setShareOption(newStyle);
              setShareMapStyle(newStyle);
            }}
          >
            <ToggleButton value="simple"><Icon.Simple title={t.simple}/>
              <span style={mapStyleButtonStyle}>{t.simple}</span>
            </ToggleButton>
            <ToggleButton value="street"><Icon.Map title={t.street}/>
              <span style={mapStyleButtonStyle}>{t.street}</span>
            </ToggleButton>
            <ToggleButton value="satellite"><Icon.Sattelite title={t.satellite} />
              <span style={mapStyleButtonStyle}>{t.satellite}</span>
            </ToggleButton>
          </ToggleButtonGroup>
          <FormGroup sx={{flexDirection:'row'}}>
            <FormControlLabel 
              control={<Switch inputRef={shareAuto} onChange={() => setShareOption()}/>} 
              label={t.autoPlay} 
              labelPlacement="start"
              title={t.autoPlay} />
          </FormGroup>

          <DialogActions className="dialogAction">
            <Icon.Link className="icon" style={dialogIconStyle} title={t.copyLink} 
              onClick={() => {
                navigator.clipboard.writeText(shareURL.current.value);
                setShareSnack(true);
                props.setShareDlg(false);
              }}/>
            <Icon.Twitter className="icon" style={dialogIconStyle} title={t.twitter}
              onClick={() => {
                window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareURL.current.value)}&text=toboggar%20-%20${encodeURIComponent(props.userMap.title ? props.userMap.title : '')}`);
              }}/>
          </DialogActions>
        </DialogContent>
      </Dialog> 
      <Snackbar 
        open={shareSnack} 
        autoHideDuration={6000} 
        anchorOrigin={{ vertical: 'bottom', horizontal:'center' }} 
        message={t.copiedLink}
        onClose={() => setShareSnack(false)}
      />
    </>
  );
}

Share.propTypes = {
  shareDlg: PropTypes.bool,
  setShareDlg: PropTypes.func,
  userMap: PropTypes.object
}

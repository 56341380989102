import { useEffect, useState, useCallback, Fragment } from 'react';
import { collection, query, where, getDocs, orderBy, limit, startAfter, Timestamp } from "firebase/firestore"; 
import { db, setHeaderInfo, isMobile, isTouchDevice, getFromCache, setIntoCache, moveToTop } from "./../common/Util";
import { useLoaderData, useNavigate, useLocation, useNavigation, ScrollRestoration } from "react-router-dom";
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import LinearProgress from '@mui/material/LinearProgress';
import Fab from '@mui/material/Fab';
import * as STYLE from "./../common/Style";
import {Icon} from "./../common/Icon";
import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import postscribe from 'postscribe';

const Element = (props) => {

  const navigate = useNavigate();
  const loadedData = useLoaderData();
  const location = useLocation();
  const t = window.t;
  const [loading, setLoading] = useState(false);
  const navigation = useNavigation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [userMaps, setUserMaps] = useState(loadedData.maps);
  const [lastSnapshot, setLastSnapshot] = useState(loadedData.lastSnapshot);
  const [snackBarMsg, setSnackBarMsg] = useState(null);

  const toggleScrolled = useCallback(() => {
    window.scrollY > 200
      ? setIsScrolled(true)
      : setIsScrolled(false)
  }, [])

  const isLoading = useCallback(() => {
    return navigation.state !== 'idle';
  }, [navigation.state]);

  const user = loadedData.user;
  setHeaderInfo(`${user.id} - Toboggar`, user.profile, user.lang);

  useEffect(() => {
    window.addEventListener('scroll', toggleScrolled);
    return () => window.removeEventListener('scroll', toggleScrolled);
  }, [toggleScrolled]);

  useEffect(() => {
    if (!isLoading())
      setLoading(false);
  },[navigation.state, loading, isLoading]);

  useEffect(() => {
    postscribe(
      '#addPlace',
      isMobile() ? `<script type="text/javascript">rakuten_design="slide";rakuten_affiliateId="0c37c7a8.b2a17ac2.0c37c7a9.69dbb093";rakuten_items="ctsmatch";rakuten_genreId="0";rakuten_size="300x160";rakuten_target="_blank";rakuten_theme="gray";rakuten_border="off";rakuten_auto_mode="on";rakuten_genre_title="off";rakuten_recommend="on";rakuten_ts="1713687585069";</script><script type="text/javascript" src="https://xml.affiliate.rakuten.co.jp/widget/js/rakuten_widget.js?20230106"></script>`
      : `<script type="text/javascript">rakuten_design="slide";rakuten_affiliateId="0c37c7a8.b2a17ac2.0c37c7a9.69dbb093";rakuten_items="ctsmatch";rakuten_genreId="0";rakuten_size="728x200";rakuten_target="_blank";rakuten_theme="gray";rakuten_border="off";rakuten_auto_mode="on";rakuten_genre_title="off";rakuten_recommend="on";rakuten_ts="1713687341313";</script><script type="text/javascript" src="https://xml.affiliate.rakuten.co.jp/widget/js/rakuten_widget.js?20230106"></script>`
    );
  }, []);
  
  async function reload() {

    setLoading(true);
    try {
      const cache = getFromCache('u:' + user.id);

      if (!cache) {
        const limit = 5;
        const mapQeury = makeMapQuery(props.user, user.id, limit, null);
  
        const [newList, lastSnapShot] = await fetchMaps(mapQeury, limit);
        setUserMaps([...newList]);
        setLastSnapshot(lastSnapShot);
        setIntoCache('u:' + user.id, {...loadedData, maps:newList, lastSnapshot: lastSnapShot});
      }
    } catch(error) {
      console.error("Error roading user maps: ", error);                
      setSnackBarMsg(error.name + ' ' + error.message);
    }
  }

  async function fetchMaps(mapQuery, limit) {
    const docSnap = await getDocs(mapQuery);
    const newList = [];
    docSnap.forEach((doc) => {
      let data = doc.data();
      data.uid = doc.id;
      newList.push(data);
    });
    let lastSnapshot;
    if (newList.length === limit)
      lastSnapshot = docSnap.docs[docSnap.docs.length - 1];

    return [newList, lastSnapshot];
  }

  const iconStyle = {
    height: '18px',
    width: '18px',
    strokeWidth: 2
  }

  const imageStyle = {
    maxWidth: "100px",
    maxHeight: "100px",
  };

  const userStyle = {
    display: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: isMobile() ? '200px 1fr' : '300px 1fr',
  }

  const profileStyle = {
    display: 'flex',
    alignItems: 'center'
  }

  const publishedStyle = {
    color: STYLE.COLOR_ON_SRFACE_VARIANT,
    fontSize: '90%',
    marginBottom: '8px'
  }

  const descStyle = {
    display: '-webkit-box',
    marginBottom: 0,
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden'
  }

  const tooldBarStyle = {
    display: 'grid',
    gridTemplateColumns: isMobile() ? '85px 1fr 135px' : '85px 1fr 190px',
    overflow: 'hidden'
  }

  const userIdStyle = {
    lineHeight: '1.2',
  }

  const listStyle = {
    wordBreak: 'break-all' 
  }

  const upIconStyle = {
    strokeWidth: 2,
    width: '20px',
    height: '20px',
    marginTop: '8px'
  }

  const fabOuter = {
    display: 'grid',
    position: 'fixed',
    top: '35px',
    right: '15px',
    gridTemplateColumns: '1fr',
    rowGap: '1em',
    justifyItems: 'end',
    zIndex: '1100',
  }
  
  const upStyle = {
    opacity: '0.5',
    display: isScrolled ? 'block' : 'none' 
  }

  const addStyle = {
    opacity: '0.8',
    fontSize: '200%'
  }

  const mapImageStyle = {
    justifyContent: 'center'
  }

  const pullContentStyle = {
    color: STYLE.COLOR_ON_SECONDARY_CONTAINER, 
    textAlign: 'center',
    backgroundColor: STYLE.COLOR_SECONDARY_CONTAINER,
    position: 'absolute',
    borderRadius: '0 0 10px 10px',
    padding: '5px 10px',
    left: '50%',
    animationName: 'pullDown',
    animationDuration: '0.5s',
    animationFillMode: 'forwards',
    zIndex: '100'
  }

  const snackBarStyle = { 
    vertical: 'top', 
    horizontal:'center' 
  }

  const logoStyle = {
    width: isMobile() ? '140px' : '200px',
    transform: isMobile() ? 'rotate(-10deg)' : 'none',
  }

  const logoButtonStyle ={
    padding: '0',
    border: 'none',
    cursor: 'pointer',
    background: 'transparent',
  }

  return (
    <>
      <style>{`
        @keyframes pullDown {
          from {
            transform: translate(-50%, -40px);
            opacity: 0;
          }
        
          to {
            transform: translate(-50%, 0);
            opacity: 1;
          }
        }
       `}</style>
      <ScrollRestoration/>
      <AppBar position="sticky">
        <Toolbar style={tooldBarStyle}>
          <div style={{overflow:'hidden', display: 'flex', flexDirection: 'row' }}>
            <IconButton onClick={() => {
                navigate(location.state?.back ? -1 : '/');
              }}
              size="middle"
              color="primary"
              disabled={isLoading()}
            >
              <Icon.Back title={t.back} style={iconStyle}/>
            </IconButton>
            <IconButton onClick={reload}
              size="middle"
              color="primary"
              disabled={isLoading()}
            >
              <Icon.Reload title={t.reload} style={iconStyle} />
            </IconButton>
          </div>
          <div>
            <Typography variant="h6" component="h6" style={userIdStyle}>
              {user.id}
            </Typography>
            {user.name}
          </div>
          <div>
            <button style={logoButtonStyle} onClick={
              () => moveToTop(navigate)
            }>
              <img style={logoStyle} alt="Toboggar" src="/logo.svg" />
            </button>
          </div>
        </Toolbar>
        {loading ? <LinearProgress /> : null}
      </AppBar>
      <Card style={userStyle}>
        <CardHeader
          avatar={
            <Avatar 
              alt={user.name} 
              src={user.image ? user.image : null} 
              sx={{ width: isMobile() ? 60 : 80, height: isMobile() ? 60 : 80 }}>
              {user.id.slice(0, 1)}
            </Avatar>
          }
          title={user.id}
          subheader={user.name}
          titleTypographyProps={{variant: 'h6', color: 'secondary.main'}}
        />
        <CardContent style={profileStyle}>
          <Typography color="text.secondary">
            {user.profile}
          </Typography>
        </CardContent>
      </Card>
      <div id="addPlace" style={{
        width:'100%', 
        height: isMobile() ? '160px' : '200px', 
        textAlign: 'center', 
        overflow:'hidden'
      }}></div>
      <Container maxWidth="xl" style={listStyle}>
        <InfiniteScroll 
          dataLength={userMaps.length}
          next={async () => {
            try {
              const limit = 7;
              const mapQeury = makeMapQuery(props.user, user.id, limit, lastSnapshot);
  
              const [moreList, lastSnapShot] = await fetchMaps(mapQeury, limit);
              let newList;
              setUserMaps(prevItems => {
                newList = [...prevItems, ...moreList]
                return newList;
              });
              setLastSnapshot(lastSnapShot);
              setIntoCache('u:' + user.id, {...loadedData, maps: newList, lastSnapshot: lastSnapShot});
            } catch(error) {
              console.error("Error loading user maps: ", error);                
              setSnackBarMsg(error.name + ' ' + error.message);
            }
          }} 
          hasMore={lastSnapshot && userMaps.length < 1000}
          pullDownToRefresh={isTouchDevice()}
          refreshFunction={reload}
          pullDownToRefreshThreshold={50}
          releaseToRefreshContent={
            <div style={pullContentStyle}><Icon.Reload/>{t.reload}</div>
          }
          >
          <List sx={{ width: '100%'}}>
          {userMaps.map((map) => {
            return <Fragment key={map.uid}>
              <ListItem disablePadding divider
                secondaryAction={props.user && (user.uid === props.user.uid) ? 
                  <IconButton onClick={() => {
                    setLoading(true);
                    navigate('/edit/' + map.uid, { state: {back: true} });
                  }} 
                    size="middle"
                    color="primary"
                  >
                    <Icon.Edit title={t.edit} style={iconStyle} />
                  </IconButton>
                  : null}>
                <ListItemButton onClick={() => {
                  setLoading(true);
                  navigate('/m/' + map.uid, { state: {back: true} });
                }}>
                  <ListItemIcon sx={{width:100, mr:2}} style={mapImageStyle}>
                    {map.image ? 
                      <img alt={map.title} style={imageStyle} src={map.image}/>
                      : <Icon.Image />}
                  </ListItemIcon>
                  <ListItemText
                    primary={map.title ?? t.noTitle}
                    secondary={<>
                      <span style={publishedStyle}>{map.draft ? t.draft : t.dtFormater(map.published.toDate())}</span>
                      <span style={descStyle}>{map.description}</span>
                    </>}
                    primaryTypographyProps={{sx:{ color: 'primary.main' }}}
                  />
                </ListItemButton>
              </ListItem>
            </Fragment>
          })}
          </List>
        </InfiniteScroll>
      </Container>
      <div style={fabOuter}>
        {props.user ? <Fab size="large" color="secondary" style={addStyle} onClick={() => navigate('/edit', { state: {back: true} })}>
          <Icon.Plus title={t.add} />
        </Fab> : null}
        <Fab size="small" color="primary" style={upStyle} 
          onClick={() => window.scrollTo({top: 0, behavior: "smooth" })}>
          <Icon.Up title={t.up} style={upIconStyle}/>
        </Fab>
      </div>
      <Snackbar 
        autoHideDuration={6000} 
        anchorOrigin={snackBarStyle} 
        open={snackBarMsg !== null} 
        message={snackBarMsg}
        onClose={() => setSnackBarMsg(null)}
      />
    </>
  );
    
};

function LoaderHandle(user) {
  return async ({request, params}) => {

    const cache = getFromCache('u:' + params.userId);

    if (cache) 
      return cache;
    
    const pageLength = 5;
    const usersRef = collection(db, "users");
    const userQuery = query(usersRef, where("id", "==", params.userId), where("deleted", "==", null));
    const mapQeury = makeMapQuery(user, params.userId, pageLength, null);
    const res = {user: null, maps: [], lastSnapshot: null};

    await Promise.all([getDocs(userQuery), getDocs(mapQeury)]).then((doc) => {
      if (doc[0].empty)
        throw new Response("Not Found", { status: 404 });
      else {
        res.user = doc[0].docs[0].data();
        res.user.uid = doc[0].docs[0].id;
      }
      doc[1].forEach((doc) => {
        const data = doc.data();
        data.uid = doc.id;
        res.maps.push(data);
      });
      if (res.maps.length === pageLength)
        res.lastSnapshot = doc[1].docs[doc[1].docs.length - 1];
    }).catch((error) => {
      console.error(error);
      if (error.name || error.message)
        throw new Response((error.name ?? '') + ' ' + (error.message ?? ''), { status: 403 });
      else
        throw error;
    });
    setIntoCache('u:' + params.userId, res);
    return res;
  }
}

async function LoaderForBot ({request, params}){
  // bypass App check for bot
  const res = await fetch(`${process.env.REACT_APP_FB_FC_DOMAIN}/botProxy/u/${params.userId}`);
  if (!res.ok)
    throw new Response('Error', { status: res.status });
  const json = await res.json();
  json.maps.forEach(item => {
    item.published = new Timestamp(item.published._seconds, item.published._nanoseconds);
  });
  return json;
}

function makeMapQuery(currentUser, listUser, numOfLimit, lastSnapshot) {
  const mapsRef = collection(db, "maps");
  let q;
  if (currentUser && currentUser.userId === listUser)
    q = query(mapsRef, where("uid", "==", currentUser.uid),
      orderBy("published", "desc"), limit(numOfLimit));
  else 
    q = query(mapsRef, where("auther", "==", listUser), where("draft", "==", false),
      orderBy("published", "desc"), limit(numOfLimit));

  if (lastSnapshot)
    q = query(q, startAfter(lastSnapshot));

  return q;
}

Element.propTypes = {
  user: PropTypes.object,
}

const User = {
  Element,
  LoaderHandle,
  LoaderForBot
};

export default User;
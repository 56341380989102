import * as STYLE from "./../common/Style";
import { changeMapStyle, convertRgba, newMap, mapReset, getLineItems } from "../common/Util";
import PropTypes from 'prop-types';
import { SearchBox } from '@mapbox/search-js-react';

export function resizeMapContiner(mapContainer, clientRect, map) {
  let mapContainerStyle = mapContainer.style;
  mapContainerStyle.width = clientRect.width + 'px';
  mapContainerStyle.height = clientRect.height + 'px';
  mapContainerStyle.top = clientRect.y + 'px';
  mapContainerStyle.left = clientRect.x + 'px';
  if (map)
    map.resize();
}

export function setMapToStreet(map, moveCurPos, onLoad) {
  changeMapStyle(map, 'simple', onLoad, true);
  if (moveCurPos)
    navigator.geolocation.getCurrentPosition((p) => {
      map.jumpTo({
        center: [p.coords.longitude, p.coords.latitude]
      });
    });
}

export function setMap(props, mapPlaceHolder, center, moveCurPos, onLoad) {

  const mapContanerStyle = props.mapContainer.current.style;
  if (mapContanerStyle.display === 'block') {
    return null;
  } else {
    mapContanerStyle.display = 'block';
    mapContanerStyle.position = 'absolute';
    resizeMapContiner(props.mapContainer.current, mapPlaceHolder.getBoundingClientRect());
    let map = props.map.current;

    if (map) {
      map.resize();
      mapReset(map);
      map.jumpTo({center: center, zoom: 3});
      setMapToStreet(map, moveCurPos, onLoad);
    } else {
      newMap(props, 'simple', null, center, 3);
      map = props.map.current;
      map.on('load', () => setMapToStreet(map, moveCurPos, onLoad));
    }
  }

}

export function resizeImage(image, maxLength, callBack) {
  let img = new Image();
  img.addEventListener(
    "load",
    () => {
      const context = document.createElement('canvas').getContext('2d');
      const { naturalHeight: beforeHeight, naturalWidth: beforeWidth } = img;
      const isLandscape = beforeHeight < beforeWidth;
      const needResize = (isLandscape && beforeWidth > maxLength)
        || (!isLandscape && beforeHeight > maxLength);
      if (needResize) {
        let newWidth = isLandscape ? maxLength : beforeWidth * (maxLength / beforeHeight);
        let newHeight = isLandscape ? beforeHeight * (maxLength / beforeWidth) : maxLength;
        context.canvas.width = newWidth;
        context.canvas.height = newHeight;
        context.drawImage(img, 0, 0, newWidth, newHeight);
      } else {
        context.canvas.width = beforeWidth;
        context.canvas.height = beforeHeight;
        context.drawImage(img, 0, 0);
      }
      if (callBack)
        callBack(context.canvas.toDataURL("image/jpeg"));
    },
    false,
  );
  img.src = image;
}

export function AimingImage(props) {

  return (
  <>
    <style>{`
      .aimingStyle {
        pointer-events:none;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 75px;
        height: 75px;
        position: relative;
        border: solid 5px ${STYLE.COLOR_THEME[props.mode].COLOR_ERROR}40;
      }
      .aimingCenterStyle {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        vertical-align: middle;
        color: ${STYLE.COLOR_THEME[props.mode].COLOR_ERROR};
        line-height: 1;
        width: 20px;
        height: 3px;
        background: currentColor;
        border-radius: 0.1em;
        position: relative;
      }
      .aimingCenterStyle:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: inherit;
        border-radius: inherit;
        transform: rotate(90deg);
      }
    `}</style>
    <div className='aimingStyle'>
      <div className='aimingCenterStyle' ></div>
    </div>
  </>
  );
}

AimingImage.propTypes = {
  mode: PropTypes.string,
}


export function MapSearch(props) {
  return (
    <SearchBox 
      accessToken={process.env.REACT_APP_MB_KEY}
      map={props.map}
      options={{language: window.t.lang}}
      placeholder=""
      value=""
      interceptSearch={(query) => {
        if (query) {
          const lngLat = query.split(/[ ,]+/);

          if (lngLat.length === 2 && isFinite(lngLat[0].trim()) && isFinite(lngLat[1].trim())) {
            const lat = parseFloat(lngLat[0].trim());
            const lng = parseFloat(lngLat[1].trim());
            if (lat >= -90 && lat <= 90 && lng >= -180 && lat <= 180) {
              props.map.flyTo({center: [lng, lat]});
              return null;
            }
          }

          const pattern = /^(\d*)° *(\d*)['′] *(\d+(?:\.\d+)?)["″] *([NS])[ ,]*(\d*)° *(\d*)['′] *(\d+(?:\.\d+)?)["″] *([EW])$/;
          if (pattern.test(query.trim())) {
            const minSecs = query.trim().match(pattern);
            const lat = (minSecs[4] === 'S' ? -1 : 1) * (parseInt(minSecs[1]) + (parseInt(minSecs[2]) / 60) + parseFloat(minSecs[3] / 3600));
            const lng = (minSecs[8] === 'W' ? -1 : 1) * (parseInt(minSecs[5]) + (parseInt(minSecs[6]) / 60) + parseFloat(minSecs[7] / 3600));
            if (lat >= -90 && lat <= 90 && lng >= -180 && lat <= 180) {
              props.map.flyTo({center: [lng, lat]});
              return null;
            }
        }
        }

        return query;
      }}
      theme={{
        variables: {
          colorPrimary: STYLE.COLOR_PRIMARY,
          colorSecondary: STYLE.COLOR_ON_SRFACE_VARIANT,
          colorText: STYLE.COLOR_ON_SURFACE,
          colorBackground: convertRgba(STYLE.COLOR_BACKGROUND, 0.6),
          colorBackgroundHover: STYLE.COLOR_SURFACE_VARIANT,
          colorBackgroundActive: STYLE.COLOR_SURFACE,
          border: `1px solid ${STYLE.COLOR_PRIMARY}`,
          borderRadius: '10px',
        },
        cssText: `input.Input {color: white !important; height: 1.6em} 
          input.Input:focus {border:none} 
          div.SearchBox:focus {border: 1px solid ${STYLE.COLOR_PRIMARY}}
          .SearchIcon {fill: ${convertRgba(STYLE.COLOR_ON_BACKGROUND)}}`
      }}
    />
  );
}

export function makeLineGeojson(userMap) {
  return {
    "type": "FeatureCollection",
    "name": "line",
    "features": getLineItems(userMap)
  }
}

MapSearch.propTypes = {
  map: PropTypes.object,
}

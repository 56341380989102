import { useRouteError, useNavigate } from "react-router-dom";
import { setHeaderInfo } from "./common/Util";
import Button from '@mui/material/Button';
import { Icon } from "./common/Icon";
import * as STYLE from "./common/Style";

export default function ErrorPage(props) {
  const error = useRouteError();
  const t = window.t;
  const navigate = useNavigate();
  setHeaderInfo(`Error - Toboggar`, null, t.lang);

  const outerStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  const statusStyle = {
    color: STYLE.COLOR_PRIMARY,
    fontSize: '5vw',
    lineHeight: '5vw'
  }

  return (
    <div style={outerStyle}>
      <h2 style={statusStyle}>{error.status}</h2>
      <p>{error.data ? error.data : t.generalError}</p>
      <Button 
        startIcon={<Icon.ToHome/>} 
        onClick={() => navigate('/')}>
        {t.toHome}
      </Button>
    </div>
  );
}


import App from './App';
import { db, isMobile } from "./common/Util";
import { useState, useEffect, useCallback, useRef } from 'react';
import { doc, getDoc, serverTimestamp, writeBatch } from "firebase/firestore"; 
import i18n from './common/I18n'
import * as STYLE from "./common/Style";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import LinearProgress from '@mui/material/LinearProgress';
import {Icon} from "./common/Icon";
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Alert from '@mui/material/Alert';

function Auth() {
  
  const t = window.t;

  const [authFinished, setAuthFinished] = useState(false);
  const [newAuthUser, setNewAuthUser] = useState(false);
  const [user, setUser] = useState(null);
  const [reauth, setReauth] = useState(0);
  const nameRef = useRef(null);
  const logoRef = useRef(null);
  const [nameError, setNameError] = useState();
  const userNameRef = useRef(null);
  const [userNameError, setUserNameError] = useState();
  const [userNameHelpText, setUserNameHelpText] = useState(t.userNameLength);
  const [loading, setLoading] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState(null);
  const [alertMsg, setAlertMsg] = useState(null);

  const forceReauth = useCallback(() => setReauth((counter) => {
    setAuthFinished(false);
    return counter + 1;
  }), []);

  useEffect(() => {
    setUser(null);
    onAuthStateChanged(getAuth(), (authUser) => {
      (async() => {
        if (authUser) {
          const docRef = doc(db, "users", authUser.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            authUser.userId = docSnap.data().id;
            authUser.name = docSnap.data().name;
            authUser.image = docSnap.data().image;
            window.t = i18n[docSnap.data().lang];
            setUser(authUser);
          } else {
            // new user, they has auth data, but no user data in db.
            setNewAuthUser(authUser);
          }
        }
        setAuthFinished(true);
      })();
    });
  }, [reauth]);
  
  useEffect(() => {
    if (user?.lang)
      window.t = i18n[user.lang];
  }, [user]);

  useEffect(() => {
    async function fetchLogo() {
      const response = await fetch("/logo.svg");
      const svg = await response.text();
      if (logoRef.current.innerHTML === '') {
        logoRef.current.innerHTML = svg;
        const title = logoRef.current.querySelectorAll('svg #gt path');
        title.forEach((path) => {
          const keyframe = [];
  
          keyframe.push({ opacity: 0 });
  
          let timming = Math.random() * 0.5;
          keyframe.push({ opacity: 0.2, offset: timming });
  
          timming = timming + 0.05;
          keyframe.push({ opacity: 0.8, offset: timming});
          keyframe.push({ opacity: 0, offset: timming + 0.01 });
          keyframe.push({ opacity: 0.9, offset: timming + 0.02 });
          keyframe.push({ opacity: 0, offset: timming + 0.03 });
          keyframe.push({ opacity: 1, offset: timming + 0.04 });
  
          let increase = 0;
          timming = timming + 0.05 + Math.random() * 0.8;
          while (timming < 0.97) {
            keyframe.push({ opacity: 1, offset: timming });
            keyframe.push({ opacity: 0, offset: timming + 0.01 });
            keyframe.push({ opacity: 1, offset: timming + 0.02 });
            timming = timming + 0.05 + Math.random() * (1.1 + increase * 0.1);
            increase = increase + 3;
          }
          keyframe.push({ opacity: 1});
          animate(path, keyframe);
        });
      }
    }
    if (!authFinished)
      fetchLogo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function animate(path, keyframe) {
    try {
      const animation = path.animate(keyframe, { duration: 2500, fill: "forwards" });
      await animation.finished;
      animation.commitStyles();
      animation.cancel();
    } catch (error) {
      
    }
  }
  const muiTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: STYLE.COLOR_PRIMARY,
      },
      secondary: {
        main: STYLE.COLOR_SECONDARY,
      },
    },
    typography: {
      button: {
        textTransform: 'none'
      }
    }
  });
  
  const outerStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  const containerStyle = {
    width: '90%', 
    maxWidth: 600,
    paddingTop:'2em',
    margin:'0 auto',
    display: 'flex',
    flexDirection: 'column'
  }

  const captionStyle = {
    color: STYLE.COLOR_TERTIARY,
    margin: '10px 0 5px',
    paddingLeft: '10px',
  }

  const headerLogoStyle = {
    width: isMobile() ? '140px' : '200px',
    transform: isMobile() ? 'rotate(-10deg)' : 'none'
  }

  const importantMsgStyle = {
    fontWeight: 'bold',
    color: STYLE.COLOR_SECONDARY
  }

  let content = null;
  if (newAuthUser)
    content = (
      <>
      <AppBar>
        <Toolbar >
          <img style={headerLogoStyle} alt="Toboggar" src="/logo.svg"/>
        </Toolbar>
        {loading ? <LinearProgress /> : null}
      </AppBar>
      <Toolbar />
      {alertMsg ? <Alert severity="error">
            {alertMsg}
      </Alert> : null}
      <div style={containerStyle}>
        <h2 style={captionStyle}>{t.createAccount}</h2>
        <Card>
          <CardContent>
            <ul>
              <li>{t.userNameLength}</li>
              <li>{t.userNameChar}</li>
              <li>{t.nameLength}</li>
              <li style={importantMsgStyle}>{t.nameNeverChange}</li>
            </ul>
            <TextField 
              label={t.userName}
              error={userNameError}
              inputRef={userNameRef}
              inputProps={{ maxLength: 15}}
              fullWidth
              onChange={() => {
                let helpText = t.userNameLength;
                const re = /^\w{5,15}$/;
                if (userNameRef.current?.value?.length < 5 )
                  setUserNameError(true);
                else if (!re.test(userNameRef.current.value)) {
                  helpText = t.userNameChar;
                  setUserNameError(true);
                } else
                  setUserNameError(false);
                setUserNameHelpText(helpText);
              }}
              helperText={userNameHelpText}
              variant="filled" required />
            <TextField 
              sx={{mt:'20px'}}
              label={t.name}
              error={nameError}
              inputRef={nameRef}
              inputProps={{ maxLength: 30 }}
              fullWidth
              onChange={() => setNameError(!nameRef.current.value)}
              helperText={nameError ? t.required : null}
              variant="filled" required />
          </CardContent>
          <CardActions>
            <Button
              size="small"
              fullWidth
              startIcon={<Icon.Ok/>} 
              disabled={(userNameError !== false) || (nameError !== false)}
              onClick={() => {
                setLoading(true);
                setAlertMsg(null);
                const batch = writeBatch(db);
                batch.set(doc(db, "users", newAuthUser.uid), {
                  id: userNameRef.current.value,
                  name: nameRef.current.value.trim(),
                  registered: serverTimestamp(),
                  lang: t.lang,
                  deleted: null,
                  cleaned: null
                });
                batch.set(doc(db, "userIds", userNameRef.current.value.toLowerCase()), 
                  {userRef: newAuthUser.uid});

                batch.commit().then(() => {
                  newAuthUser.userId = userNameRef.current.value;
                  newAuthUser.name = nameRef.current.value.trim();
                  newAuthUser.lang = t.lang;
                  setUser(newAuthUser);
                  setNewAuthUser(null);
                }).catch((e) => {
                  console.error("Error adding document: ", e);
                  if (e.code === 'permission-denied') {
                    setUserNameError(true);
                    setUserNameHelpText(t.userNameUsed);
                    setAlertMsg(t.userNameUsed);
                  } else
                    setSnackBarMsg(e.message);
                  setLoading(false);
                });
              }
              }>{t.ok}
            </Button>
          </CardActions>
        </Card >
      </div>
      <Snackbar 
        autoHideDuration={6000} 
        anchorOrigin={{ vertical: 'top', horizontal:'center' }} 
        open={snackBarMsg !== null} 
        message={snackBarMsg}
        onClose={() => setSnackBarMsg(null)}
      />
      </>
    );
  else if (authFinished)
    content = (<App user={user} setUser={setUser} forceReauth={forceReauth} />);
  else
    content = (
    <>
      <style>
      {`
        svg {
          filter: drop-shadow(0px 3px 10px #71f7ed);
          width: ${isMobile() ? '80vw' : '100%'};
        }
        svg #gt path {
          opacity: 0;
        }
      `}
      </style>
      <div ref={logoRef} style={outerStyle}></div>
    </>);

  return (
    <ThemeProvider theme={muiTheme}>
      <style>{`
        body {
          background-color: ${STYLE.COLOR_BACKGROUND};
          color: ${STYLE.COLOR_ON_BACKGROUND};
          overscroll-behavior: none;
        }
        label.MuiInputLabel-filled {
          transform: translate(12px, 6px) scale(1);
        }
        label.MuiInputLabel-filled.MuiInputLabel-shrink {
          transform: translate(12px, 0) scale(0.75);
        }
        input.MuiInputBase-input.MuiFilledInput-input {
          padding-bottom: 13px
        }
      `}
      </style>
      {content}
    </ThemeProvider>
  );
}

export default Auth;

import { TooltipIcon, CalendarIcon } from "./../common/Util";
import { useRef, useState, useCallback } from 'react';
import { resizeImage } from "./MapEditUtil";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import { Icon } from "../common/Icon";
import * as STYLE from "./../common/Style";
import ImageLoader from "../common/ImageLoader";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import PropTypes from 'prop-types';

export default function HeaderEditor(props) {

  const t = window.t;
  const dateRef = useRef();
  const [headerForm, setHeaderForm] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  
  const resizeImageCB = useCallback((image) => {
    setImageLoading(true);
    resizeImage(image, 200, (image) => {
      setHeaderForm((headerForm) => {
        headerForm.image = image; 
        return headerForm;
      });
      setImageLoading(false);
    });
  }, []);

  const headerStyle = {
  }

  const headerContentStyle = {
    display: 'grid', 
    gap: '8px',
    gridTemplateColumns: '100px 1fr',
  }

  const headerDisplayStyle = {
    ...headerContentStyle,
  }

  const headerEditStyle = {
    ...headerContentStyle,
    gridTemplateRows: 'auto auto auto auto',
    paddingTop: '8px'
  }

  const imageStyle = {
    maxHeight: '100px',
    maxWidth: '100px'
  }

  const headerItemStyle = {
    margin: 0
  }

  const descStyle = {
    fontSize: '90%'
  }

  const headerDescStyle = {
    ...headerItemStyle,
    ...descStyle
  }

  const headerAttributeStyle = {
    ...headerItemStyle,
    gridColumn: '1/3'
  }

  const headerActionStyle = {
    justifyContent: 'flex-end',
    paddingTop: 0,
  }

  const lableStyle = {
    color: STYLE.COLOR_ON_SRFACE_VARIANT,
    marginRight: '10px',
    fontSize: '14px'
  }

  const iconStyle = {
    height: '18px',
    width: '18px',
    strokeWidth: 2
  }

  const imageActionStyle = {
    textAlign: 'center'
  }

  const imageEditorStyle = {
    display: 'flex', 
    flexDirection:'column', 
    rowGap: '8px'
  }

  const imageOuterStyle = {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    minHeight: '80px',
  }

  const imagePreviewStyle = {
    ...imageOuterStyle,
    flexGrow: 1
  }
  
  return (
    <Card style={headerStyle}>
      {props.editItem === 0 ? 
        null :
        <CardHeader
          title={props.userMap.title ?? t.noTitle}
          subheader={props.userMap.draft ? t.draft : t.dtFormater(props.userMap.published.toDate())}
          titleTypographyProps={{variant: 'span', color: 'secondary.main'}}
          subheaderTypographyProps={{fontSize: '80%'}}
          sx={{display: 'flex'}}
        />
      }
      <CardContent sx={{pt: 0, pb:0}}>
        {props.editItem === 0 ? 
          // Header edit mode
          <div style={headerEditStyle}>
            <TextField
              value={headerForm.title}
              label={t.mapTitle}
              inputProps={{maxLength: 50}}
              variant="filled"
              sx={{gridColumn: '1/3'}}
              error={!headerForm.title || headerForm.title.trim() === ''}
              helperText={!headerForm.title || headerForm.title.trim() === '' ? t.required : `${headerForm?.title?.length ?? 0}/50`}
              onChange={(e) => setHeaderForm({...headerForm, title : e.target.value})}
              required 
              fullWidth
            />
            <div style={imageEditorStyle}>
              <div style={imagePreviewStyle}>
                {headerForm.image ? 
                  <img alt={headerForm.title} style={imageStyle} src={headerForm.image}/> 
                  : 
                  <Icon.Image />
                }
              </div>
              <div style={imageActionStyle}>
                <ImageLoader 
                  buttonStyle={iconStyle} 
                  buttonType="icon"
                  onLoad={resizeImageCB}
                  disabled={imageLoading}
                />
                {headerForm.image ? 
                  <IconButton 
                    color="primary" 
                    size="small"
                    onClick={() => setHeaderForm({...headerForm, image: null})}
                  >
                    <Icon.Delete title={t.delete} style={iconStyle}/>
                  </IconButton>
                :
                  null}
              </div>
            </div>
            <TextField 
              value={headerForm.description}
              label={t.descMap}
              multiline
              rows={4}
              fullWidth
              onChange={(e) => setHeaderForm({...headerForm, description : e.target.value})}
              sx={{gridColumn: '2/3'}}
              inputProps={{maxLength: 300}}
              variant="filled"
              helperText={`${headerForm?.description?.length ?? 0}/300`}
              />
            <div style={headerAttributeStyle}>
              <span style={lableStyle}>{t.baseDate}<TooltipIcon helpText={t.baseDateTips}/></span>
              <LocalizationProvider dateAdapter={AdapterDayjs} dateFormats={t.calendarFormat}>
                <DatePicker 
                  slotProps={{ textField: { 
                    variant: "filled", 
                    clearable: true,
                    style:{width: "180px"}
                  }}}
                  views={['year', 'month', 'day']}
                  slots={{openPickerIcon: CalendarIcon}}
                  inputRef={dateRef}
                  format={t.dateFormat}
                  dayOfWeekFormatter={(_day, weekday) => `${weekday.locale(t.lang).format('dd')}`}
                  defaultValue={headerForm.stDate ? dayjs(headerForm.stDate) : null}
                />
              </LocalizationProvider>
            </div>
            <p style={headerAttributeStyle}>
              <span style={lableStyle}>{t.reverse}<TooltipIcon helpText={t.reverseTips}/></span>
              <Switch 
                color="primary" 
                checked={headerForm.order === 'reverse'}
                onClick={(e) => setHeaderForm({...headerForm, order : e.target.checked ? 'reverse' : null})}
              />
            </p>
          </div> 
        :
          <div style={headerDisplayStyle}>
            <div style={imageOuterStyle}>
              {props.userMap.image ? 
                <img alt={props.userMap.title} style={imageStyle} src={props.userMap.image}/> 
                : 
                <Icon.Image />}
            </div>
            <p style={headerDescStyle}>{props.userMap.description}</p>
            <p style={headerAttributeStyle}><span style={lableStyle}>{t.baseDate}</span>{props.userMap.stDate ? t.dFormater(new Date(props.userMap.stDate + "T00:00")) : '-'}</p>
            <p style={headerAttributeStyle}>
              <span style={lableStyle}>{t.reverse}</span>
              <Switch color="primary" disabled checked={props.userMap.order === 'reverse'} />
            </p>
          </div>
        }
      </CardContent>
      <CardActions style={headerActionStyle}>
        {props.editItem === 0 ? 
          // Header edit mode
          <>
            <Button
              size="small"
              startIcon={<Icon.Ok style={iconStyle}/>} 
              disabled={!headerForm.title || headerForm.title.trim() === '' || props.loading}
              onClick={() => {
                const stDate = dayjs(dateRef.current.value, t.dateFormat);
                headerForm.stDate = stDate.isValid() ? stDate.format("YYYY-MM-DD") :  null;
                props.updateUserMap(headerForm, () => props.changeEditItem(-1));
              }}
            >{t.ok}
            </Button>
            <Button
              onClick={() => props.changeEditItem(-1)}
              size="small"
              startIcon={<Icon.Cancel style={iconStyle}/>} 
            >{t.cancel}
            </Button>
          </> 
        :
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              props.changeEditItem(0);
              const editData = {
                title: props.userMap.title, 
                image: props.userMap.image, 
                description: props.userMap.description, 
                stDate: props.userMap.stDate, 
                order: props.userMap.order
              };
              setHeaderForm(editData);
            }}
          >
            <Icon.Edit style={iconStyle} title={t.edit} />
          </IconButton>
        }
      </CardActions>
    </Card>
  );
};

HeaderEditor.propTypes = {
  userMap: PropTypes.object,
  loading: PropTypes.bool,
  updateUserMap: PropTypes.func,
  editItem: PropTypes.number,
  changeEditItem: PropTypes.func,
}

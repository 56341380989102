import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Snackbar from '@mui/material/Snackbar';
import { getAuth, signOut } from "firebase/auth";
import { Icon } from "../common/Icon";
import PropTypes from 'prop-types';
import { isMobile, moveToTop } from "../common/Util";

export default function Header(props) {

  const t = window.t;

  const [anchorMenu, setAnchorMenu] = useState(null);
  const openMenu = Boolean(anchorMenu);
  const navigate = useNavigate();
  const [snackBarMsg, setSnackBarMsg] = useState(null);
  const year = (new Date()).getFullYear();

  const userMenuStyle = {
    textOverflow: 'ellipsis', 
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  };

  const menuLinkStyle = {
    fontSize: '14px',
    minHeight: '16px'
  }

  const userIdStyle = {
    display: 'block', 
    fontWeight: 'bold',
    fontSize: '120%', 
    textAlign: 'left', 
    lineHeight: '1.1', 
    marginTop: '5px'
  }

  const dummyProgressBar = {
    height: '4px'
  }

  const logoStyle = {
    width: isMobile() ? '140px' : '200px',
    transform: isMobile() ? 'rotate(-10deg)' : 'none',
  }

  const logoButtonStyle ={
    padding: '0',
    border: 'none',
    cursor: 'pointer',
    background: 'transparent'
  }
  
  return (
    <>
      <AppBar position="static" sx={{height:'64px'}}>
        <Toolbar>
          <div style={{flexGrow: 1}}>
            <button style={logoButtonStyle} onClick={() => {
                moveToTop(navigate);
            }}>
              <img style={logoStyle} alt="Toboggar" src="/logo.svg" />
            </button>
          </div>
          <IconButton
            size="middle"
            color="primary"
            sx={{mr:1}}
            onClick={() => navigate('/search')}
          >
            <Icon.Search/>
          </IconButton>
          {props.user ?
              <Button
              onClick={() => {
                  navigate('/u/' + props.user.userId, { state: {back: true} });
                  props.setLoading(true);
              }}
              startIcon={
                  <Avatar
                  alt={props.user.name}
                  src={props.user.image ? props.user.image : null} >
                  {props.user.userId.slice(0, 1)}
                  </Avatar>
              }
              >
              <div style={userMenuStyle}>
                  <span style={userIdStyle}>{props.user.userId}</span>
                  <span>{props.user.name}</span>
              </div>
              </Button >
              :
              <Button variant="outlined" 
                startIcon={<Icon.Login />} 
                onClick={() => navigate('/signin')}
                id="login-button"
                sx={{mr: '10px'}}
              >
                {t.login}
              </Button>
          }
          <IconButton         
            aria-controls={openMenu ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? 'true' : undefined}
            onClick={(event) => setAnchorMenu(event.currentTarget)}>
            <Icon.Menu />
          </IconButton>
        </Toolbar>
      </AppBar>
      {props.loading ? <LinearProgress /> : <div style={dummyProgressBar}></div>}
      <Menu
        id="basic-menu"
        anchorEl={anchorMenu}
        open={openMenu}
        onClose={() => setAnchorMenu(null)}
        MenuListProps={{
        'aria-labelledby': 'basic-button',
        }}
      >
        {props.user ? 
          <MenuItem onClick={() => {
          setAnchorMenu(null);
            props.setLoading(true);
            navigate('/profile');
          }}>
              <ListItemIcon><Icon.Profile/></ListItemIcon>
              <ListItemText> {t.profile}</ListItemText>
          </MenuItem> : null
        }
        {props.user ? 
          <MenuItem onClick={() => {
              setAnchorMenu(null);
              signOut(getAuth()).then(() => {
                props.forceReauth();
              }).catch((error) => {
                console.error("Error sing out: ", error);                
                setSnackBarMsg(`Error:${error.message}(${error.code})`);
              });
            }}>
            <ListItemIcon><Icon.Logout/></ListItemIcon>
            <ListItemText> {t.logout}</ListItemText>
          </MenuItem> : null
        }
        {props.user ? <Divider /> : null}
        <MenuItem onClick={() => window.open('https://twitter.com/toboggar', '_blank')} sx={menuLinkStyle}>X - @toboggar</MenuItem>
        <MenuItem onClick={() => window.open('https://www.instagram.com/toboggar/', '_blank')} sx={menuLinkStyle}>Instagram - @toboggar</MenuItem>
        <Divider />
        <MenuItem onClick={() => {
          setAnchorMenu(null);
          props.setLoading(true);
          navigate('/rule/tu');
        }} sx={menuLinkStyle}>{t.termsOfUse}</MenuItem>
        <MenuItem onClick={() => {
          setAnchorMenu(null);
          props.setLoading(true);
          navigate('/rule/pp');
        }} sx={menuLinkStyle} >{t.privacyPolicy}</MenuItem>
        <MenuItem onClick={() => {
          setAnchorMenu(null);
          props.setLoading(true);
          navigate('/rule/cp');
        }} sx={menuLinkStyle} >{t.contentsPolicy}</MenuItem>
        <MenuItem onClick={() => {
          setAnchorMenu(null);
          props.setLoading(true);
          navigate('/about');
        }} sx={menuLinkStyle} >{t.aboutContactUs}</MenuItem>
        <Divider />
        <MenuItem 
          sx={{...menuLinkStyle, justifyContent: 'center'}} 
          onClick={() => window.location.reload()}>
            (C) 2024{year > 2024 ? ` - ${year}` : ''} Toboggar
        </MenuItem>
      </Menu>
      <Snackbar 
        autoHideDuration={6000} 
        anchorOrigin={{ vertical: 'top', horizontal:'center' }} 
        open={snackBarMsg !== null} 
        message={snackBarMsg}
        onClose={() => setSnackBarMsg(null)}
      />
    </>
  );
}

Header.propTypes = {
  user: PropTypes.object,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  forceReauth: PropTypes.func,
}

import { useRef, useState, forwardRef } from 'react';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, initializeFirestore } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { Icon } from "../common/Icon";
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import * as STYLE from "./../common/Style";
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import mapboxgl from 'mapbox-gl';
import polyline from '@mapbox/polyline';

export function useAsyncReference(value) {
    const ref = useRef(value);
    const [, setForceRender] = useState(false);
  
    function updateState(newState) {
      ref.current = newState;
      setForceRender(s => !s);
    }
  
    return [ref, updateState];
}
  
export function sanitaize(str, nullStr, urlToLink) {
  if (str) {
    let ret = str.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#39;');
    if (urlToLink) {
      const replacePattern1 = /(\b(https):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
      ret = ret.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');
    }
    return ret;
  } else
    return nullStr;
};

export function numToK(num) {
  if (!num || num <= 0)
    return '0';
  else if (num < 1000)
    return num;
  else
    return (Math.round(num * 0.01) / 10) + 'K'; 
}

export function isMobile() {
  return matchMedia('(max-width: 480px)').matches;
}

export function isTouchDevice() {
  return !window.matchMedia("(any-hover:hover)").matches;
} 

export function setHeaderInfo(title, description, lang) {
  document.title = title;
  document.querySelector("meta[name='description']").setAttribute("content", description ?? '');
}

export function LoadingIcon(props) {
  return (
  <>
    <style>{`

      .loader {
        width: 40px;
        height: 20px;
        --c:no-repeat radial-gradient(farthest-side,#4fdbd1 93%,#4fdbd100);
        background:
          var(--c) 0    0,
          var(--c) 50%  0;
        background-size: 8px 8px;
        position: relative;
        clip-path: inset(-200% -100% 0 0);
        animation: l6-0 1.5s linear infinite;
        display: inline-block;
        top: 15px;
        margin: 0 0.5em;
      }
      .loader:before {
        content: "";
        position: absolute;
        width: 8px;
        height: 12px;
        background: #4fdbd1;
        left: -16px;
        top: 0;
        animation: 
          l6-1 1.5s linear infinite,
          l6-2 0.5s cubic-bezier(0,200,.8,200) infinite;
      }
      .loader:after {
        content: "";
        position: absolute;
        inset: 0 0 auto auto;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #4fdbd1; 
        animation: l6-3 1.5s linear infinite;
      }
      @keyframes l6-0 {
        0%,30%  {background-position: 0  0   ,50% 0   }
        33%     {background-position: 0  100%,50% 0   }
        41%,63% {background-position: 0  0   ,50% 0   }
        66%     {background-position: 0  0   ,50% 100%}
        74%,100%{background-position: 0  0   ,50% 0   }
      }
      @keyframes l6-1 {
        90%  {transform:translateY(0)}
        95%  {transform:translateY(15px)}
        100% {transform:translateY(15px);left:calc(100% - 8px)}
      }
      @keyframes l6-2 {
        100% {top:-0.1px}
      }
      @keyframes l6-3 {
        0%,80%,100% {transform:translate(0)}
        90%         {transform:translate(26px)}
      }`
      }
    </style>
    <div className='loader' {...props}></div>
  </>);
}

const firebaseConfig = {
  apiKey: process.env.REACT_APP_GGL_KEY,
  authDomain: "auth.toboggar.com",
  projectId: "toboggar",
  storageBucket: "toboggar.appspot.com",
  messagingSenderId: "481649474741",
  appId: "1:481649474741:web:81b0b7cdee1d3eb60652fc",
  measurementId: "G-G52D960Q1X"
};

const app = initializeApp(firebaseConfig);
initializeFirestore(app, {
  ignoreUndefinedProperties: true,
});
getAnalytics(app);
if (process.env.NODE_ENV === 'development') {
  window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_AC_KEY;
}
initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider('6LfpQqopAAAAAL0eOC45RXGR8VASiN1MgmaASIAg'),
  isTokenAutoRefreshEnabled: true
});

export function convertRgba(color_code, alpha = 1) {
  return 'rgba(' + [
    parseInt(color_code.substring(1,3), 16),
    parseInt(color_code.substring(3,5), 16),
    parseInt(color_code.substring(5,7), 16),
    alpha
  ].join() + ')';
}

const CACHE_RETENTION = 180000;
const CACHE = new Map();

export function getFromCache(key) {
  const now = Date.now();
  CACHE.forEach((e, i) => {
    if ((now - e.stored) > CACHE_RETENTION)
      CACHE.delete(i)
  })

  const cachedData = CACHE.get(key);
  if (cachedData)
    return cachedData.data;
  else
    return null;
}

export function setIntoCache(key, data) {
  CACHE.set(key, {stored: Date.now(), data: data});
}

export function clearCache() {
  CACHE.clear();
}

let LAST_SEARCH_WARD = null;
export function getLastSearchWards() {
  return LAST_SEARCH_WARD;
}
export function setLastSearchWards(words) {
  LAST_SEARCH_WARD = words;
}

export function moveToTop(navigate) {
  const TIMEOUT_DURATION = 3600000;
  if ((Date.now() - window.startAt) > TIMEOUT_DURATION)
    window.location.href = '/';
  else
    navigate('/');
}

export const db = getFirestore(app);
export const MAP_ID = {data: 'points', layer: 'makers', image: 'pin-marker', delLangEvent: 'changeStyle', lineData: 'line', lineLayer: 'line', lineTitle: 'line_title'};
export const searchClient = algoliasearch('C99FX2SBN5', process.env.REACT_APP_AG_KEY);

const HelpIcon = forwardRef((props, ref) => {
  return (
    <span {...props} ref={ref}>
      <Icon.Help style={{height: '14px', width: '14px', marginLeft:'7px'}}/>
    </span>
  );
});
export function TooltipIcon(props) {
  return (<Tooltip title={props.helpText} enterTouchDelay={0} arrow><HelpIcon/></Tooltip>);
}
TooltipIcon.propTypes = {
  helpText: PropTypes.string,
}

export const CalendarIcon = forwardRef((props, ref) => {
  return (
    <Icon.Calendar style={{height: '18px', width: '18px'}}/>
  );
});

export function newMap(props, mapStyle, bounds, center, zoom) {

  const mapStyleOption = {
    container: props.mapContainer.current,
    style: STYLE.MAPBOX_STYLE[mapStyle].uri,
  };
  if (bounds)
    mapStyleOption.bounds = bounds;
  else {
    mapStyleOption.center = center;
    mapStyleOption.zoom = zoom;
  }

  const map = new mapboxgl.Map(mapStyleOption);
  map.addControl(new mapboxgl.NavigationControl(), 'bottom-left');
  if (mapStyle !== 'satellite') {
    // in case of satellite style, it couldn't be applied language module.
    let mapLanguage = new MapboxLanguage();
    map.addControl(mapLanguage);
    map.once(MAP_ID.delLangEvent, () => map.removeControl(mapLanguage));
  }

  map.on('error', () => {
    props.mapContainer.current.style.display = 'none';
    props.map.current = null;
  });

  map.on('contextmenu', (e) => {
    if (e.originalEvent.shiftKey)
      navigator.clipboard.writeText(`${e.lngLat.lat},${e.lngLat.lng}`);
  });

  props.map.current = map;
}

export function changeMapStyle(map, newStyle, onLoad, force, setStyleLoading) {
  if (map) {
    if (map.getStyle().name !== STYLE.MAPBOX_STYLE[newStyle].name) {
      map.fire(MAP_ID.delLangEvent);
      map.setStyle(STYLE.MAPBOX_STYLE[newStyle].uri);
      map.once('style.load', () => {
        if (onLoad)
          onLoad(STYLE.MAPBOX_STYLE[newStyle].mode);
        if (setStyleLoading)
          setStyleLoading(false);
      });
      if (newStyle !== 'satellite') {
        // in case of satellite style, it couldn't be applied language module.
        let mapLanguage = new MapboxLanguage();
        map.addControl(mapLanguage);
        map.once(MAP_ID.delLangEvent, () => map.removeControl(mapLanguage));
      }
    } else if (force && onLoad) {
      onLoad(STYLE.MAPBOX_STYLE[newStyle].mode);
    }
  }
}

export function mapReset(map) {
  if (map.getLayer(MAP_ID.layer))
    map.removeLayer(MAP_ID.layer);
  if (map.getSource(MAP_ID.data))
    map.removeSource(MAP_ID.data);
  if (map.hasImage(MAP_ID.image))
    map.removeImage(MAP_ID.image);
  if (map.getLayer(MAP_ID.lineLayer))
    map.removeLayer(MAP_ID.lineLayer);
  if (map.getLayer(MAP_ID.lineTitle))
    map.removeLayer(MAP_ID.lineTitle);
  if (map.getSource(MAP_ID.lineData))
    map.removeSource(MAP_ID.lineData);
}

export function SwitchStyle(props) {

  const t = window.t;
  const [mapStyle, setMapStyle] = useState(props.default ?? 'simple');
  const [styleLoading, setStyleLoading] = useState(false);
 
  const mapStyleStyle = {
    borderRadius: "10px",
    border: `solid 1px ${STYLE.COLOR_ON_PRIMARY_CONTAINER}`,
    backgroundColor: convertRgba(STYLE.COLOR_BACKGROUND, mapStyle === 'satellite' ?  0.8 : 0.6),
    pointerEvents: 'auto',
    ...props.style
  }

  const iconStyle = {
    height: props.iconSize ?? 'inherit',
    width: props.iconSize ?? 'inherit',
  }

  return (
    <ToggleButtonGroup
      color="primary"
      value={mapStyle}
      exclusive
      disabled={props.loading || styleLoading}
      onChange={(event, newStyle) => {
        if (newStyle !== null) {
          setStyleLoading(true);
          changeMapStyle(props.map, newStyle, props.onLoad, false, setStyleLoading);
          setMapStyle(newStyle);
        }
      }}
      style={mapStyleStyle}
      size={props.size}
    >
      <ToggleButton value="simple"><Icon.Simple title={t.simple} style={iconStyle}/></ToggleButton>
      <ToggleButton value="street"><Icon.Map title={t.street} style={iconStyle}/></ToggleButton>
      <ToggleButton value="satellite"><Icon.Sattelite title={t.satellite} style={iconStyle}/></ToggleButton>
    </ToggleButtonGroup>
  );
}

SwitchStyle.propTypes = {
  map: PropTypes.object,
  onLoad: PropTypes.func,
  loading: PropTypes.bool,
  style: PropTypes.object,
  default: PropTypes.string,
  size: PropTypes.string,
  iconSize: PropTypes.string,
}


export function getLineItems(userMap) {
  return userMap.features.filter(item => item.properties.polyline).map(item => {
    const latLngArray = polyline.decode(item.properties.polyline);
    if (latLngArray.length < 2)
      return null;
    const lngLatArray = latLngArray.map(latLng => [latLng[1], latLng[0]]);
    return {
      "type": "Feature",
      "id": item.id,
      "properties": { "title": item.properties.lineTitle ?? null },
      "geometry": { "type": "LineString", "coordinates": lngLatArray }
    };
  }).filter(item => item !== null);
}

